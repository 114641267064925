<template>
  <Header />
  <main>
    <section class="aboutPage__hero aboutPage-hero">
      <div class="container">
        <div class="aboutPage-hero__row">
          <h1 class="aboutPage-hero__title hero__title">
            <span>Образцы </span>
            <span>документов</span>
          </h1>
        </div>

        <div class="info-hero__docs">
          <Accordion
            v-for="(doc, idx) in documents"
            :key="idx"
            :title="doc.title"
            :description="doc.description"
          />
        </div>
      </div>
    </section>
  </main>
 
</template>

<script>
  import Header from '@/components/Header/Header'
  import Accordion from '@/components/ui/Accordion'

  export default {
    name: 'Documents',

    components: {
      Header,
      Accordion
    },

    data() {
      return {
        documents: [
          {
            title: 'Образец договора',
            description: 'Вы можете ознакомиться с документом, скачав файл <a target="_blank" href="/docs/dogovor.pdf">здесь</a>', 
            active: false
          },
          {
            title: 'Образец договора Horse Paradise',
            description: 'Вы можете ознакомиться с документом, скачав файл <a target="_blank" href="/docs/dogovor_HP.pdf">здесь</a>', 
            active: false
          },
          {
            title: 'Анкета участника',
            description: '<a target="_blank" href="https://docs.google.com/forms/d/e/1FAIpQLScae-3lM6mt3oySDqjyCqT3Zq8MfNBCbgoTylQU2X_HmadVTQ/viewform?usp=sf_link">Нажмите</a>, чтобы заполнить здесь', 
            active: false
          },
          {
            title: 'Список вещей',
            description: 'Вы можете ознакомиться с документом, скачав файл <a target="_blank" href="/docs/spisok_veshchej.png">здесь</a>', 
            active: false
          },
          // {
          //   title: 'Список вещей. Новый Год',
          //   description: 'Вы можете ознакомиться с документом скачав файл <a target="_blank" href="/docs/spisok_veshchej.png">здесь</a>', 
          //   active: false
          // },
          {
            title: 'Справка о пребывании ребенка в лагере',
            description: 'Вы можете ознакомиться с документом, скачав файл <a target="_blank" href="/docs/spravka_o_prebyvanii_rebenka_v_lagere.pdf">здесь</a>', 
            active: false
          },
          {
            title: 'Санитарно-эпидемиологическое заключение 2024',
            description: 'Вы можете ознакомиться с документом, скачав файл <a target="_blank" href="/docs/sanitarno-epidemiologicheskoe_zaklyuchenie.pdf">здесь</a>', 
            active: false
          },
          
        ]
      }
    }
  }
</script>

<style>

</style>
<template>
      <main>
         <section 
            class="progsSection progsSection-hero" 
            :style="{'background-image': 'url(' + template.get_preview + ') !important'}"
         >
            <div class="container">
               <div class="progsSection-hero__text" v-if="Object.keys(template).length > 0">
                  <div class="progsSection-hero__title">
                     <span class="progsSection-hero__title_big hero__title">{{ template.title.split(' ')[0] }} <span class="dot">.</span></span>
                     <p class="progsSection-hero__title_small">Подробнее о программе и расписании лагеря</p>
                  </div>
                  <div class="progsSection-hero__subtitle hero__title center__text-layot">
                     <span v-if="template.title.split(' ')[1]">
                        {{ template.title.split(' ')[1] }}
                     </span>
                  </div>
                  <div class="progsSection-hero__subtitle hero__title">
                     <span v-if="template.title.split(' ')[2]">
                        {{ template.title.split(' ')[2] }}
                        <span class="dot">.</span>
                     </span>                     
                  </div>
                  <p class="progsSection-hero__desc-adapt">Подробнее о программе и расписании лагеря</p>
               </div>
               <ul class="progsSection-hero__list">
                  <li class="progsSection-hero__item" @click="GoToLager()">
                     Программа лагеря
                  </li>
                  <li class="progsSection-hero__item" @click="GoToWait()">
                     Что ждёт подростка в лагере
                  </li>
<!--                   <li class="progsSection-hero__item">
                     Советы и замечания
                  </li> -->
                  <li class="progsSection-hero__item" @click="GoToTeams()">
                     Команда лагеря
                  </li>
                  <li class="progsSection-hero__item" @click="GoToPlace()">
                     Место проведения программы
                  </li>
                  <li class="progsSection-hero__item" @click="GoToTransf()">
                     Трансфер
                  </li>
                  <li class="progsSection-hero__item" @click="GoToPriceSmeni()">
                     Стоимость смены
                  </li>
                  <li class="progsSection-hero__item" @click="GoToDocument()">
                     Частые вопросы
                  </li>
               </ul>
            </div>
         </section>
         <section class="progsSection price">
            <div class="container">
               <div class="price__card">
                  <div class="price__title progsSection__title">
                     Стоимость путёвки
                  </div>
                  <ul class="price__list" id="priceSublist1">
                     <li v-for="list in programm.programm_smena"
                        class="price__item price-item" 
                        :class="{'checked': smena == list.programm + ':' + list.number}"
                     >
                        <input type="radio" name="campShifts" :id="list.number + 'stShift'" v-model="smena" :value="list.programm + ':' + list.number">
                        <label :for="list.number + 'stShift'" :class="{'active': !smena}">
                           <div class="price-item__name">{{ list.number }} смена</div>
                           <div class="price-item__date">{{ CorDate(list.date_start) }} - {{ CorDate(list.date_end) }}</div>
                           <div class="price-item__price">{{ list.price }} ₽</div>
                        </label>
                     </li>
                  </ul>
                  <button class="price__btn" v-if="!smena" :disabled="!smena">
                     Выберите даты
                  </button>
                  <button class="price__btn active" v-else @click="GoToReserve()">
                     Забронировать
                  </button>
               </div>
            </div>
         </section>
         <section class="progsSection brief" v-if="Object.keys(template).length > 0">
            <div class="container">
               <div class="progsSection__title brief__title">Коротко о программе</div>
               <div class="brief__card">
                  <div class="brief__text">
                     {{ template.small_disc }}
                  </div>
                  <div class="brief__scroll">
                     <div class="brief__row progsSection-team__slider">
                        <div class="brief__image" v-for="list in template.images_disc">
                           <img v-if="list.get_image" :src="list.get_image" alt="">
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </section>
         <section class="progsSection wait" ref="podrostok">
            <div class="container">
               <div class="wait__title progsSection__title">{{ template.titleTimes }}</div>
               <div class="wait__column">
                  <div class="wait__card wait-card wait-morning" v-for="list in template.bl_times">
                     <div class="wait-card__row">
                        <div class="wait-card__main">
                           <div class="wait-card__title">{{ list.name }}</div>
                           <div class="wait-card__text">
                              <p v-html="list.description">
                              </p>
                           </div>
                           <div class="wait-card__images wait-morning__slider">
                           <ul class="wait-progs__list wait-morning__list">
                              <li v-for="item in list.tags"
                                 class="wait-progs__item">
                                 {{ item.name }}
                              </li>
                           </ul>
                           </div>
                        </div>
                        <div class="wait-card__progs wait-progs">
                              <div class="wait-card__image">
                                 <img v-if="list.get_image" :src="list.get_image" alt="">
                              </div>
                        </div>
                     </div>
                  </div>
                  <div class="wait__card wait-card wait-fun" ref="programm_lager" v-if="template.bl_events.length > 0">
                     <div class="wait-fun__row">
                        <div class="wait-card__item" v-for="list in template.bl_events">
                           <div class="wait-card__title">{{ list.name }}</div>
                           <div class="wait-card__text">{{ list.description }}</div>
                           <div class="wait-card__images wait-fun__slider">
                              <div class="wait-card__image">
                                 <img v-if="list.get_image" :src="list.get_image" alt="">
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </section>
         <section class="progsSection-team" ref="teams" v-if="template.titleTemplate">
            <div class="container">
               <div class="progsSection__title progsSection-team__title">{{ template.titleTemplate }}</div>
               <div class="progsSection-team__card wow swing">
                  <div class="progsSection-team__row">
                     <div class="progsSection-team__text">
                        <p v-html="template.descriptionTemplateFirst"></p>
                     </div>
                     <div class="progsSection-team__subtitle" v-html="template.descriptionTemplateSesond"></div>
                  </div>
                  <div class="brief__scroll">
                     <div class="progsSection-team__images progsSection-team__slider">
                        <div class="progsSection-team__image" v-for="list in template.images_team">
                           <img :src="list.get_image" alt="">
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </section>
         <section class="progsSection-place" ref="place" v-if="Object.keys(template).length > 0 && template.placeTitle">
            <div class="container">
               <div class="progsSection-place__wrapper">
                  <div class="progsSection-place__row">
                     <div class="progsSection-place__text">
                        <div class="progsSection__title progsSection-place__title">{{ template.placeTitle }}</div>
                        <div class="progsSection-place__desc" v-html="template.placeDescription">
                        </div>
                     </div>
                     <div class="progsSection-place__conditions conditions">
                        <div class="conditions__grid">
                           <div class="conditions__item conditions__item_title" v-if="template.placeInfoFt">Где находится</div>
                           <div class="conditions__item conditions__item_main" v-if="template.placeInfoFt">{{ template.placeInfoFt }}</div>
                           <div class="conditions__item conditions__item_title" v-if="template.placeInfoSnd">Проживание</div>
                           <div class="conditions__item conditions__item_main" v-if="template.placeInfoSnd">
                              {{ template.placeInfoSnd }}
                           </div>
                           <div 
                              class="conditions__item conditions__item_title" 
                              v-if="template.placeInfoTh">
                              В номере
                           </div>
                           <div class="conditions__item conditions__item_main" v-if="template.placeInfoTh">
                              {{ template.placeInfoTh }}
                           </div>
                        </div>
                     </div>
                  </div>
                  <div class="progsSection-place__images progsSection-place__images_up" v-if="template.imgsPlace.length > 0">
                     <div class="progsSection-place__image" v-for="list in template.imgsPlace.slice(0, 4)">
                        <img :src="list.get_image" alt="">
                     </div>
                  </div>
                  <div class="progsSection-place__images progsSection-place__images_down">
                     <div class="progsSection-place__image" v-for="list in template.imgsPlace.slice(4, 8)">
                        <img :src="list.get_image" alt="">
                     </div>
                  </div>
                  <div class="brief__scroll">
                     <div class="progsSection-place__slider">
                        <div class="progsSection-place__image" v-for="list in template.imgsPlace.slice(0, 4)">
                           <img :src="list.get_image" alt="">
                        </div>
                     </div>
                  </div>
                  <div class="feed place-card" v-for="list in template.tplace">
                     <div v-if="list.name == 'Трансфер'" ref="transf"></div>
                     <div class="feed__row place-card__row">
                        <div class="feed__title place-card__title">{{ list.name }}</div>
                        <div class="feed__text place-card__text" v-html="list.description"></div>
                     </div>
                     <div class="feed__shedule">
                        <div class="feed__time" v-for="item in list.tags" v-html="item.name"></div>
                     </div>
                     <div class="brief__scroll" v-if="Object.keys(template).length > 0">
                        <div class="feed__images place-card__images progs__slider" v-if="list.images.length > 0">
                           <div class="feed__image place-card__image" v-for="item in list.images">
                              <img :src="item.get_image" alt="">
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </section>
         <section class="progsSection price second" ref="price_smeni">
            <div class="container">
               <div class="price__card">
                  <div class="price__title progsSection__title">
                     Стоимость путёвки
                  </div>
                  <ul class="price__list" id="priceSublist1">
                     <li v-for="list in programm.programm_smena"
                        class="price__item price-item" 
                        :class="{'checked': smena == list.programm + ':' + list.number}"
                     >
                        <input type="radio" name="campShifts" :id="list.number + 'stShift'" v-model="smena" :value="list.programm + ':' + list.number">
                        <label :for="list.number + 'stShift'" :class="{'active': !smena}">
                           <div class="price-item__name">{{ list.number }} смена</div>
                           <div class="price-item__date">{{ CorDate(list.date_start) }} - {{ CorDate(list.date_end) }}</div>
                           <div class="price-item__price">{{ list.price }} ₽</div>
                        </label>
                     </li>
                  </ul>
                  <button class="price__btn" v-if="!smena" :disabled="!smena">
                     Выберите даты
                  </button>
                  <button class="price__btn active" v-else @click="GoToReserve()">
                     Забронировать
                  </button>
               </div>
            </div>
         </section>
         <section class="progsSection faq" ref="document">
            <div class="container">
               <div class="faq__title progsSection__title">Частые вопросы</div>
               <div id="faq-question" class="faq-question">
                  <div 
                     class="faq-item"
                     v-for="list, index in info"
                     @click="list.active = !list.active"
                     >
                     <div class="faq-item__title" :class="{'active': list.active}">
                        <span>{{ list.name }}</span>
                     </div>
                     <div class="faq-item__subtitle" :class="{'styleObject': list.active}">
                        <span v-html="list.description"></span>
                     </div>
                  </div>
               </div>
               <div class="faq__card">
                  Забронировать путёвку и получить ответы на любые вопросы можно позвонив нам по телефону: <a
                     href="tel:+74956496360">+7(495)
                     649-63-60</a>. Будем рады
                  ответить Вам 7 дней в неделю с
                  10:00
                  до 20:00.
               </div>
               <div class="faq__card faq__card_adapt">
                  <p>Мы указываем полную стоимость. Это означает, что Вам не придётся ни за что доплачивать - ни за
                     трансфер, ни за лазертаг, ни за фирменную футболку.
                  </p>
                  <p>Сто один рубль с каждой путёвки идёт на добрые дела.
                  </p>
                  <p>Узнать подробнее про способы оплаты можно здесь.
                  </p>
               </div>
            </div>
         </section>
      </main>
</template>

<style scoped>

</style>

<script>
   import moment from 'moment';
   import axios from 'axios'
   import {WOW} from 'wowjs';

   export default {
      name: 'Kidsee',
      data() { 
         return {
            smena: null,
         }
      },
      props: {
         programm: {
            type: Object,
            default: {},
         },
         info: {
            type: Array,
            default: [],
         },
         template: {
            type: Object,
            default: {},
         },
      },      
      mounted() {
         new WOW().init();
      },
      methods: {
           GoToLager() {
               this.$refs.programm_lager.scrollIntoView({ behavior: "smooth" });
           },
           GoToWait() {
               this.$refs.podrostok.scrollIntoView({ behavior: "smooth" });
           },
           GoToTeams() {
               this.$refs.teams.scrollIntoView({ behavior: "smooth" });
           },
           GoToPlace() {
               this.$refs.place.scrollIntoView({ behavior: "smooth" });
           },
           GoToTransf() {
               this.$refs.transf[0].scrollIntoView({ behavior: "smooth" });
           },
           GoToPriceSmeni() {
               this.$refs.price_smeni.scrollIntoView({ behavior: "smooth" });
           },
           GoToDocument() {
               this.$refs.document.scrollIntoView({ behavior: "smooth" });
           },
           GoToReserve() {
               const newParam = this.smena.split(':')
               this.$router.push({ path: '/reserve', query: { progId: newParam[0], smenaId: newParam[1] }})
           },
           CorDate(date) {
                moment.locale('ru');
                const datasort = moment(String(date)).format('DD MMMM')
                return datasort
           },
      },
      created() {

      },
   }
</script>
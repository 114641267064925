<template>
  <swiper
    :slides-per-view="1.2"
    :space-between="30"
    :loop="true"
    :breakpoints="swiperOptions.breakpoints"
    @swiper="onSwiper"
    @slideChange="onSlideChange"
    class="team__slider team-slider"
  >
    <swiper-slide 
       v-for="list in teams"
       class="team-slider__item team-member" 
    >
        <div class="team-member__image">
           <img :src="list.get_preview" alt="">
        </div>
        <div class="team-member__name">{{ list.name }}</div>
        <div class="team-member__desc">{{ list.description }}</div> 
    </swiper-slide>
  </swiper>
</template>

<style>
.team-member + .team-member {
  margin-left: 0px;
}
</style>
<script>
  import { Swiper, SwiperSlide } from 'swiper/vue';
  import 'swiper/css';
  import { onMounted } from "@vue/runtime-core";
  import AOS from "aos";


  export default {
    name: 'TeamCarousel',
    data() {
      return {
        swiperOptions: {
          breakpoints: {       
            330: {       
               slidesPerView: 1.01,
               spaceBetween: 35    
            },          
            400: {       
               slidesPerView: 1.5,   
            },   
            501: {       
               slidesPerView: 2, 
            },   
            601: {       
               slidesPerView: 2.5,     
            },   
            769: {       
               slidesPerView: 3,  
            },
            1024: {
               slidesPerView: 4.2,
            },                                  
          }   
       }
      }
    },
    components: {
      Swiper,
      SwiperSlide,
    },
    props: {
      teams: {
        type: Array,
        default: []
      }
    },
    setup() {
      onMounted(() => {
        AOS.init();
      });
      const onSwiper = (swiper) => {
      };
      const onSlideChange = () => {
      };
      return {
        onSwiper,
        onSlideChange,
      };
    },
  };
</script>
<template>
   <footer class="footer">
      <div class="container">
         <div class="footer__row">
            <div class="footer-main">
               <div class="footer__logo">
                  <router-link to="/">
                     <svg width="59" height="67" viewBox="0 0 59 67" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clip-path="url(#clip0_234_191)">
                           <path
                              d="M0 7.70287L2.44514 21.6477L10.7074 20.1826L10.1066 16.7563L5.36886 17.5961L3.52456 7.07751L0 7.70287Z"
                              fill="#CCFA57" />
                           <path d="M9.09912 6.08854L11.5432 20.0344L14.9936 19.4511L12.5443 5.47791L9.09912 6.08854Z"
                              fill="#CCFA57" />
                           <path
                              d="M27.7157 9.99102C26.6321 3.51678 20.1139 4.13583 20.1139 4.13583L14.1797 5.18684L16.653 19.1559L22.5632 18.1048C22.5632 18.1048 28.7993 16.4653 27.7157 9.99102ZM22.2204 14.6754L19.7126 15.1189L18.4587 8.01827L20.9738 7.57264C20.9738 7.57264 23.7429 7.35403 24.2978 10.6448C24.8526 13.9355 22.2204 14.6754 22.2204 14.6754Z"
                              fill="#CCFA57" />
                           <path
                              d="M27.5674 2.81364L30.0125 16.7585L38.8506 15.2198L38.256 11.8313L32.885 12.7836L32.5287 10.7498L37.3699 9.89117L36.8035 6.6593L32.0345 7.50432L31.7126 5.6682L37.0104 4.72859L36.4002 1.24658L27.5674 2.81364Z"
                              fill="#CCFA57" />
                           <path
                              d="M47.2558 8.3535C47.2558 8.3535 49.2496 6.92412 48.842 3.98654C48.0009 -0.810288 43.0803 0.0620531 43.0803 0.0620531L37.5547 1.04265L40.004 15.0211L43.5108 14.3989L42.724 9.9153L44.0657 9.66937L47.189 13.7105L51.0552 13.0253L47.2558 8.3535ZM44.2893 6.56677L42.2151 6.93673L41.6581 3.79735L43.748 3.4274C43.748 3.4274 45.1639 3.29707 45.4042 4.75273C45.6446 6.20838 44.2893 6.56783 44.2893 6.56783V6.56677Z"
                              fill="#CCFA57" />
                           <path
                              d="M2.90918 24.5979L10.3418 67L29.7911 63.5506L28.3303 55.2139L17.7305 57.0942L11.7587 23.0277L2.90918 24.5979Z"
                              fill="#CCFA57" />
                           <path d="M22.3584 21.1485L13.7144 22.6815L14.9482 29.7198L23.5923 28.1868L22.3584 21.1485Z"
                              fill="#CCFA57" />
                           <path d="M23.9361 30.1481L15.292 31.6812L19.3627 54.9014L28.0067 53.3683L23.9361 30.1481Z"
                              fill="#CCFA57" />
                           <path
                              d="M24.2642 20.8102L31.6978 63.2133L39.1513 61.8911L34.6936 36.4629L54.5421 32.9431L53.2819 25.751L33.3342 29.2887L33.0165 27.4757L52.9632 23.938L51.5661 15.9681L24.2642 20.8102Z"
                              fill="#CCFA57" />
                           <path
                              d="M36.7378 37.8081L40.905 61.5799L59.0001 58.3712L57.6855 50.8733L48.2352 52.5496L47.9039 50.662L57.3554 48.9856L56.2435 42.648L46.8966 44.3065L46.556 42.3632L55.9039 40.7058L54.8329 34.5994L36.7378 37.8081Z"
                              fill="#CCFA57" />
                        </g>
                        <defs>
                           <clipPath id="clip0_234_191">
                              <rect width="59" height="67" fill="white" />
                           </clipPath>
                        </defs>
                     </svg>
                  </router-link>
               </div>
               <div class="footer-image adaptPc">
                  <img src="@/assets/img/HorizontalLogosV2.svg">
               </div>
               <div class="footer-copy">
                  <div class="footer-copy__rights">{{ copyRightsText }}</div>
               </div>
            </div>
            <div class="footer-progs">
               <div class="footer-progs__title footer__title">
                  Программы
               </div>
               <ul class="footer-progs__list footer__list">
                  <li v-for="list in programms_inf">
                     <router-link :to="'/programms/' + list.slug" class="footer-progs__link footer__link">{{ list.title
                        }}</router-link>
                  </li>
                  <!--                  <li>
                    <a class="footer-progs__link footer__link" href="">Лидер - Шаг вперёд. Лето
                    </a>
                 </li>
                 <li>
                    <a class="footer-progs__link footer__link" href="">Галилео Кидс. Лето</a>
                 </li>
                 <li>
                    <a class="footer-progs__link footer__link" href="">Лидер в Кадре. Лето
                    </a>
                 </li>
                 <li>
                    <a class="footer-progs__link footer__link" href="">
                       Выпускной
                    </a>
                 </li>
                 <li>
                    <a class="footer-progs__link footer__link" href="">Конный лагерь Horse Paradise</a>
                 </li> -->

               </ul>
            </div>
            <div class="footer-about">
               <div class="footer-about__title footer__title">О компании</div>
               <ul class="footer__list footer-about__list">
                  <li>
                     <router-link to="/about" class="footer-about__link footer__link">О нас</router-link>
                  </li>
                  <li>
                     <router-link to="/team" class="footer-about__link footer__link">Команда</router-link>
                  </li>
                  <li>
                     <router-link to="/contact" class="footer-about__link footer__link">Контакты</router-link>
                  </li>
                  <li>
                     <router-link to="/info" class="footer-about__link footer__link">Правовая информация</router-link>
                  </li>
                  <!--                  <li>
                    <a class="footer-about__link footer__link" href="">Политика конфиденциальности</a>
                 </li> -->
               </ul>
            </div>
            <div class="footer-contacts">
               <div class="footer__title footer-contacts__title">Контакты</div>
               <ul class="footer__list footer-contacts__list">
                  <li>
                     <a href="tel:+74956496360" class="footer__link footer-contacts__link">+7 (495) 649-63-60</a>
                  </li>
                  <li>
                     <a href="mailto:camp@liderlife.ru" class="footer__link footer-contacts__link">camp@liderlife.ru</a>
                  </li>
                  <li>
                     <a href="https://vk.com/liderlifepub" target="_blank"
                        class="footer__link footer-contacts__link">Вконтакте</a>
                  </li>
                  <li>
                     <a href="https://t.me/+bEJ7tXoDFgAyOGJi" target="_blank"
                        class="footer__link footer-contacts__link">Телеграм</a>
                  </li>
                  <li>
                     <a href="https://www.youtube.com/user/liderlifevideo" target="_blank"
                        class="footer__link footer-contacts__link">Youtube</a>
                  </li>
                  <li>
                     <a class="footer__link footer-contacts__link cntc">г. Москва, Походный проезд, д. 4, корп. 1 (БЦ
                        Тушино). 4 этаж, офис 409-Б</a>
                  </li>
               </ul>
               <div class="footer-contacts__madeby"><a href="https://oktta.ru" _blank="true" class="link_studio">made by
                     oktta</a></div>
               <div class="footer-image adaptMob">
                  <img src="@/assets/img/HorizontalLogosV2.svg">
               </div>
            </div>
         </div>
         <div class="footer-copy">
            <div class="footer-copy__rights">{{ copyRightsText }}</div>
         </div>
      </div>
      <section
         v-bind:class="{ 'hidden': !modalWindowStatus['BackCallModal'], 'fade-in': modalWindowStatus['BackCallModal'] }">
         <BackCallModal :is-active-modal="BackCallModal" @close="closeModal" />
      </section>
   </footer>
</template>

<style scoped>
.footer-copy {
   width: 50%;
}

.adaptPc {
   display: block;
}

.cntc:hover {
   color: #fff !important;
}

.adaptMob {
   display: none;
}

@media (max-width: 1024px) {
   .adaptPc {
      display: none;
   }

   .adaptMob {
      display: block;
   }

   .footer-image {
      max-width: 220px !important;
      margin-top: 20px;
      transform: none !important;
   }
}

.link_studio {
   color: #fff;
}

.footer-image {
   transform: translateY(-50px);
   max-width: 520px;
}

.footer-image img {
   width: 100%;
}
</style>

<script>
import axios from 'axios'
import BackCallModal from '@/components/Modals/BackCallModal'
import { mapActions, mapState } from 'vuex'

const CALLBACK_FORM_DELAY = 20 * 1000;

export default {
   name: 'Footer',
   data() {
      return {
         programms_inf: [],
         timer: null
      }
   },

   components: {
      BackCallModal
   },

   mounted() {
      this.GetSmallInfoProgramms()
      this.showBackCallModal()
   },

   computed: {
      ...mapState({
         modalWindowStatus: state => state.modalWindowStatus,
      }),

      BackCallModal() {
         return this.modalWindowStatus['BackCallModal']
      },

      copyRightsText() {
         let currentYear = new Date().getFullYear()
         return `ООО «ЛидерЛайф Турс» © 2008—${currentYear} Все права защищены`
      }

   },

   methods: {
      ...mapActions([
         'modalStatusSet'
      ]),

      async GetSmallInfoProgramms() {
         await axios
            .get(`/api/v1/small_programms/`)
            .then(response => {
               this.programms_inf = response.data
            })
            .catch(error => {
               console.log(error)
            })
      },

      showBackCallModal() {
         let isWasShowBackCallModal = JSON.parse(localStorage.getItem('isWasShowBackCallModal'))

         if (!isWasShowBackCallModal) {
            this.timer = setTimeout(() => {
               this.modalStatusSet({ modal: 'BackCallModal', status: true });
            }, CALLBACK_FORM_DELAY)
         }
      },
      closeModal() {
         this.modalStatusSet({
            modal: 'BackCallModal',
            status: false,
         })
         localStorage.setItem('isWasShowBackCallModal', true)
      }
   },

   beforeDestroy() {
      console.log(321)
      clearTimeout(this.timer)
   },

}
</script>
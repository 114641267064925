<template>
  <Header />
      <main>
         <section class="contacts-hero">
            <div class="container">
               <div class="contacts-hero__title hero__title">
                  Контакты
               </div>
            </div>
         </section>
         <section class="aboutSection contacts contacts-main">
            <div class="container">
               <div class="contacts__card wow swing">
                  <div class="voskArrow_fone">
                     <img src="@/assets/img/about/voskArrow.svg">
                  </div>
                  Вы можете звонить в любой день недели — даже если уже не рабочий день, мы все равно постараемся
                  подойти к телефону
               </div>
               <div class="contacts__grid">
                  <div class="contacts__item">
                     ООО «Лидерлайф Турс»
                  </div>
                  <div class="contacts__item">
                     <a href="tel:+74956496360">+7 (495) 649-63-60</a>
                  </div>
                  <div class="contacts__item">
                     <a href="mailto:camp@liderlife.ru">camp@liderlife.ru</a>
                  </div>
                  <div class="contacts__item">
                     Москва, Походный проезд, д. 4, корп. 1 (БЦ Тушино). 4 этаж, офис 409-Б.М. Тушинская (МЦД
                     Трикотажная)
                  </div>
               </div>
               <div class="contacts__social contacts-social">
                  <div class="contacts-social__item">
                     <a href="https://vk.com/liderlifepub" target="_blank">
                        <svg width="28" height="18" viewBox="0 0 28 18" xmlns="http://www.w3.org/2000/svg">
                           <path
                              d="M26.9008 17.6134H23.8442C22.6869 17.6134 22.3292 16.6761 20.2503 14.53C18.4393 12.744 17.6383 12.5039 17.1937 12.5039C16.5694 12.5039 16.3913 12.6854 16.3913 13.5627V16.3746C16.3913 17.1318 16.1542 17.5848 14.1946 17.5848C10.2416 17.3147 6.84408 15.1186 4.87179 11.9181L4.84234 11.8681C2.55303 8.9719 0.865504 5.48131 0.0308606 1.66353L0 1.49636C0 1.04343 0.178151 0.61907 1.03945 0.61907H4.09607C4.86759 0.61907 5.16497 0.981988 5.46376 1.82784C6.97594 6.27431 9.50091 10.175 10.5404 10.175C10.9261 10.175 11.1043 9.9935 11.1043 8.99619V4.39685C10.985 2.27935 9.88667 2.09932 9.88667 1.34348C9.8993 0.939123 10.2247 0.616211 10.6231 0.616211C10.6357 0.616211 10.647 0.616212 10.6596 0.617641H15.4683C16.122 0.617641 16.359 0.980558 16.359 1.7664V7.96745C16.359 8.63184 16.6564 8.87474 16.8346 8.87474C17.2203 8.87474 17.5486 8.63184 18.2598 7.90744C19.7383 6.06856 21.0022 3.96534 21.9659 1.69782L22.0304 1.52636C22.2338 1.00771 22.722 0.647645 23.2929 0.647645C23.3195 0.647645 23.3448 0.649073 23.3714 0.650502H23.3672H26.4238C27.3441 0.650502 27.5531 1.13201 27.3441 1.79927C26.118 4.54973 24.7405 6.91298 23.1442 9.10192L23.2171 8.99619C22.8917 9.54057 22.7725 9.78204 23.2171 10.3893C23.5426 10.8422 24.6129 11.7795 25.3255 12.6268C26.3565 13.6655 27.2206 14.8743 27.8729 16.2088L27.908 16.2874C28.2054 17.1618 27.7593 17.6162 26.898 17.6162L26.9008 17.6134Z"
                               />
                        </svg>
                     </a>
                  </div>
                   <div class="contacts-social__item">
                     <a href="https://t.me/+bEJ7tXoDFgAyOGJi" target="_blank">
                        <svg width="24" height="21" viewBox="0 0 24 21" xmlns="http://www.w3.org/2000/svg">
                           <path
                              d="M23.4325 2.47095L19.9777 19.4157C19.714 20.6201 19.0283 20.8939 18.0525 20.3464L12.778 16.295L10.1408 18.8408C10.0166 19.0092 9.85701 19.146 9.67424 19.241C9.49148 19.3357 9.29026 19.3861 9.08587 19.3883L9.45509 13.9134L19.2129 4.74302C19.6612 4.35978 19.2129 4.14078 18.5799 4.52402L6.60687 12.3257L1.33238 10.6285C0.198369 10.2726 0.171995 9.4514 1.56973 8.90392L21.9029 0.691618C22.905 0.3905 23.7489 0.992734 23.4325 2.47095Z"
                        />
                        </svg>
                     </a>
                  </div>
                  <div class="contacts-social__item">
                     <a href="https://www.youtube.com/user/liderlifevideo" target="_blank">
                        <svg width="26" height="26" viewBox="0 0 26 26" fill="#fff" xmlns="http://www.w3.org/2000/svg">
                           <path d="M24.4177 6.95498C24.289 6.44084 24.0269 5.96977 23.6579 5.58934C23.2888 5.20891 22.826 4.9326 22.316 4.78831C20.4527 4.33331 12.9993 4.33331 12.9993 4.33331C12.9993 4.33331 5.54598 4.33331 3.68265 4.83165C3.17267 4.97593 2.70979 5.25224 2.34078 5.63267C1.97176 6.0131 1.70967 6.48417 1.58098 6.99831C1.23997 8.88933 1.07316 10.8076 1.08265 12.7291C1.07049 14.6651 1.23732 16.5981 1.58098 18.5033C1.72286 19.0015 1.99082 19.4546 2.35897 19.819C2.72713 20.1834 3.18304 20.4466 3.68265 20.5833C5.54598 21.0816 12.9993 21.0816 12.9993 21.0816C12.9993 21.0816 20.4527 21.0816 22.316 20.5833C22.826 20.439 23.2888 20.1627 23.6579 19.7823C24.0269 19.4019 24.289 18.9308 24.4177 18.4166C24.756 16.5399 24.9228 14.6362 24.916 12.7291C24.9281 10.7932 24.7613 8.86024 24.4177 6.95498Z" stroke="#7C57FC" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                           <path d="M10.5625 16.2716L16.7917 12.7291L10.5625 9.18665V16.2716Z" stroke="#7C57FC" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                     </a>
                  </div>
               </div>
               <div class="pos-arrow_fone">
                  <div class="posArrow_fone">
                     <img src="@/assets/img/about/arrowFone.svg">
                  </div>
               </div>
               <div class="contacts-column relative-contact">
                  <div class="contacts-column__item">
                     Вы можете звонить в любой день недели — даже если уже нерабочий день, мы все равно постараемся
                     подойти к телефону
                  </div>
                  <div class="contacts-column__item">Напоминаем, что на любую программу Вы можете заказать бесплатную
                     курьерскую доставку полного комплекта документов для поездки в лагерь (путёвка/ваучер, договор и
                     памятку).</div>
                  <!-- <button class="contacts-column__item">
                     Заказать курьерскую доставку
                  </button> -->
               </div>
            </div>
         </section>
         <section class="map">
            <iframe src="https://yandex.ru/map-widget/v1/?um=constructor%3Ae3a25c898dee5d467d9c819545718c5ce1c34410d11bb3dd505a9f195e233b7a&amp;source=constructor" width="100%" height="400" frameborder="0"></iframe>

         </section>
      </main>
</template>

<style type="text/css">

</style>

<script>
import axios from 'axios'
import Header from '@/components/Header/Header'

export default {
  name: 'Contacts',
  data() {
    return {  

    }
  },
  methods: {

  },
  components: {
    Header
  }
}
</script>

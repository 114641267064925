<template>
  <Header />
      <main>
         <section class="teamSection-hero">
            <div class="container">
               <div class="teamSection-hero__column">
                  <h1 class="teamSection-hero__title hero__title">
                     <span>Наша</span>
                     <span>команда</span>
                  </h1>
                  <div class="aboutPage-hero__subtitle teamSection-hero__subtitle">О тех, кто стоит <br> за нашим
                     проектом
                  </div>
               </div>
            </div>
            <div class="marquee-wrapper">
               <div class="marquee-infinite">
                  <div class="marquee-track">
                     <span>Наша команда – это более 50 заряженных людей, которые искренне любят работать с детьми и горят своим делом.</span>
                     <span>Каждый из них – студент или выпускник ведущего ВУЗа Москвы, прошедший подготовку в нашей школе вожатых.
                     Знакомьтесь!
                     </span>
                     <span>Наша команда – это более 50 заряженных людей, которые искренне любят работать с детьми и горят своим делом.</span>
                     <span>Каждый из них – студент или выпускник ведущего ВУЗа Москвы, прошедший подготовку в нашей школе вожатых.
                     Знакомьтесь!
                     </span>
                  </div>
                  <div class="marquee-track">
                     <span>Наша команда – это более 50 заряженных людей, которые искренне любят работать с детьми и горят своим делом.</span>
                     <span>Каждый из них – студент или выпускник ведущего ВУЗа Москвы, прошедший подготовку в нашей школе вожатых.
                     Знакомьтесь!
                     </span>
                     <span>Наша команда – это более 50 заряженных людей, которые искренне любят работать с детьми и горят своим делом.</span>
                     <span>Каждый из них – студент или выпускник ведущего ВУЗа Москвы, прошедший подготовку в нашей школе вожатых.
                     Знакомьтесь!
                     </span>
                  </div>
               </div>
            </div>
         </section>
         <section class="teachers" v-for="list, index in teams.slice().reverse()">
            <div class="container">
               <div class="teachers__title teamSection__title">{{ list.name }}</div>
               <div class="teachers__grid">
                  <div 
                     class="hide-and-seek teachers__item" 
                     v-for="item in list.category_team"
                     data-aos="fade-up" 
                     data-aos-duration="1500"
                  >
                     <div class="teachers__image">
                        <img :src="item.get_preview">
                     </div>
                     <div class="teachers__name">{{ item.name }}</div>
                     <div class="teachers__experience">{{ item.description }}</div>
                  </div>

<!--                   <a href="#" class="teachers__item_link">
                     <div class="teachers__item_icon teachers__image"></div>
                     <div class="teachers__item_link-span"><span>Стань частью команды</span> <img
                           src="@/assets/img/team/arrow.svg" alt=""></div>
                  </a> -->
               </div>
            </div>
         </section>
      </main>
</template>

<style type="text/css">

</style>

<script>
import axios from 'axios'
import Header from '@/components/Header/Header'
import AOS from "aos";

export default {
  name: 'Team',
  data() {
    return {  
      teams: [],
    }
  },
  methods: {
      async GetTeams() {
          await axios
          .get(`/api/v1/full_team/`)
          .then(response => {
              this.teams = response.data
          })
          .catch(error => {
            console.log(error)
          })
           
      },
  },
  components: {
    Header
  },
  mounted() {
   AOS.init();
  },
  created(){
      this.GetTeams()
  },
}
</script>

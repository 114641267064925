import { createRouter, createWebHistory } from 'vue-router'
import store from '../store'

// Home

import HomeView from '../views/HomeView.vue'

// Prog

import Programms from '../views/Programms/Programms.vue'

// Team

import Team from '../views/Team/Team.vue'

// Payment
// TODO: Удалить через какое-то время после интеграции
// import Payment from '../views/Payment/Payment.vue'
// import PaymentConfirm from '../views/Payment/PaymentConfirm.vue'

// Payment V2

import PaymentV2 from '../views/PaymentV2/PaymentV2.vue'
import PaymentV2Confirm from '../views/PaymentV2/PaymentV2Confirm.vue'

// Info

import Info from '../views/Info/Info.vue'

// About

import About from '../views/About/About.vue'

// Contacts

import Contacts from '../views/Contacts/Contacts.vue'

// Reserve

import Reserve from '../views/Reserve/Reserve.vue'

// SuccessMessage

import SuccessMessage from '../views/Reserve/SuccessMessage.vue'

import Documents from '../views/Documents/Documents.vue'
import Meditsina from '../views/Meditsina/Meditsina.vue'
import PriezdOtezd from '../views/PriezdOtezd/PriezdOtezd.vue'
import Reviews from '../views/Reviews/Reviews'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/programms/:program_slug',
    name: 'Programms',
    component: Programms
  },
  {
    path: '/team',
    name: 'Team',
    component: Team
  },
  {
    path: '/payment',
    name: 'PaymentV2',
    component: PaymentV2,
  },
  {
    path: '/payment/confirm',
    name: 'PaymentV2Confirm',
    component: PaymentV2Confirm  
  },
  {
    path: '/info',
    name: 'Info',
    component: Info
  },
  {
    path: '/about',
    name: 'About',
    component: About
  },
  {
    path: '/contact',
    name: 'Contacts',
    component: Contacts
  },
  {
    path: '/reserve',
    name: 'Reserve',
    component: Reserve
  },
  {
    path: '/success/reserve',
    name: 'SuccessMessage',
    component: SuccessMessage
  },
  {
    path: '/documents',
    name: 'Documents',
    component: Documents
  },

  {
    path: '/meditsina',
    name: 'Meditsina',
    component: Meditsina
  },
  {
    path: '/priezd-i-otezd',
    name: 'PriezdOtezd',
    component: PriezdOtezd
  },

  {
    path:'/reviews',
    name: 'Reviews',
    component: Reviews
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
      document.getElementById('app').scrollIntoView({ behavior: 'smooth' });
  }
})

export default router

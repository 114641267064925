<template>
  <div class="wrapper" :class="{'aboutPage__wrapper': $route.path != '/' && $route.name != 'Programms'}">
    <router-view/>
    <Footer/>
    <vue-progress-bar />

  </div>
</template>


<style>
@import '@/assets/css/style.css';
</style>

<script>

  // import '@/assets/js/app.js';
  import Footer from '@/components/Footer/Footer'
  import axios from 'axios'

  export default {
    data() {
      return {

      }
    },
    components: {
      Footer,
    },
    beforeCreate() {
      this.$store.commit('initializeStore')

    },
    mounted() {
      this.$Progress.finish();

      const token = this.$store.state.token

      if (token) {
        axios.defaults.headers.common['Authorization'] = "Token " + token
      } else {
        axios.defaults.headers.common['Authorization'] = ""
      }

      document.title = 'Больше, чем лагерь'
    },
    created() {

      this.$Progress.start();

      this.$router.beforeEach((to, from, next) => {

        if (to.meta.progress !== undefined) {
          let meta = to.meta.progress;

          this.$Progress.parseMeta(meta);
        }

        this.$Progress.start();

        next();
      });

      this.$router.afterEach((to, from) => {
        this.$Progress.finish();
      });

      console.log(this.$route.name)
    },  
    computed: {

    }
  }
</script>
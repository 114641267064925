<template>
  <Header />
  <main>
    <section class="aboutPage__hero aboutPage-hero">
      <div class="container">
        <div class="aboutPage-hero__row">
          <h1 class="aboutPage-hero__title hero__title">
            <span>Отзывы</span>
          </h1>
        </div>
        <div class="reviews">
          <div
            class="reviews__item"
            v-for="(item,idx) in reviews"
            :key="idx"
            v-html="item.text"
          >
          </div>
        </div>

        <div class="reviews__column">
          <h2 class="reviews__title">Посмотреть все отзывы</h2>

          <div class="reviews__links">
            <a 
              v-for="(link,idx) in reviewsLinks"
              :key="idx"
              class="reviews__link"
              :href="link.link"
              rel=”noreferrer”
              target="_blank"
            >
              <img 
                :src="setImg(link.icon)" 
                :alt="link.title"
                class="reviews__link-img"
              >
              {{link.title}}
            </a>
          </div>
        </div>
      </div>
    </section>
  </main>
 
</template>

<script>
  import Header from '@/components/Header/Header'

  export default {
    name: 'Reviews',

    components: {
      Header
    },

    data() {
      return {
        reviews: [
          {
            text: '“Однозначно пять звёзд! Были не в одном лагере, но дочь ни разу не приезжала из лагеря с таким количеством положительных эмоций. Мне теперь самой захотелось поехать, когда она рассказывала про активности, которые там были))”'
          },
          {
            text: '“Как человек, успевший побывать более чем в десяти различных лагерях, могу смело утверждать, что Лидерлайф - действительно особенный. Если вы искали место, в котором ваш ребёнок будет чувствовать себя комфортно, интересно, а главное продуктивно проводить своё летнее время, развивать различные навыки и просто быть счастливым, то вы нашли это место!”'
          },
          {
            text: '“Интересная, насыщенная, нетривиальная программа, камерная обстановка.<br/><br/>Дочь была уже в 4й раз в "Лидере". Очень жаль, что смены только по 2 недели (летние) и очень круто, что есть зимние со своей новогодне-волшебной атмосферой.”'
          },
          {
            text: '“Небольшой, но уютный лагерь, можно сказать домашний, вдалеке от цивилизации, не было времени и порой связи, чтобы сидеть в телефоне и соцсетях, только живое общение, на свежем воздухе, с такими же по духу подростками.<br/><br/>Все дети как-то так удачно подобрались, что не было конфликтов, была дружеская атмосфера и поддержка. Замечательные вожатые, которые были на одной волне с детьми. Вкусная еда, своя молочная продукция, прогулки на лошадях, песни у костра под гитару. Однозначно рекомендую этот лагерь.”'
          }
        ],

        reviewsLinks: [
          {
            title: 'Отзывы на яндексе',
            link:'https://yandex.ru/maps/org/liderlayf_turs/177216729638/reviews/?ll=37.410990%2C55.834100&z=14',
            icon: 'yandex.svg'
          },
          {
            title: 'Отзывы инкэмп',
            link:'https://incamp.ru/reviews/lider-uspeshnyiy-start/',
            icon: 'incamp.png'
          },
          {
            title: 'Отзывы на гугл',
            link:'https://www.google.cl/maps/place/%D0%94%D0%B5%D1%82%D1%81%D0%BA%D0%B8%D0%B9+%D0%BB%D0%B0%D0%B3%D0%B5%D1%80%D1%8C+Liderlife/@55.834171,37.4081888,17z/data=!4m8!3m7!1s0x46b54a5ee0f27527:0xa66a23f5f91de1d9!8m2!3d55.834171!4d37.4107691!9m1!1b1!16s%2Fg%2F1hdzvywxs?entry=ttu',
            icon: 'google.svg'
          },
        ]
      }
    },

    methods: {
      setImg(link) {
        return require(`@/assets/img/${link}`)
      }
    }
  }
</script>

<style>
  .reviews {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
  .reviews__item {
    background-color: #7C57FC;
    color: #fff;
    padding: 30px;
    border-radius: 30px;
    font-size: 16px;
    line-height: 150%;
    letter-spacing: -0.035em;
    margin-bottom: 25px;
  }

  .reviews__item:nth-child(odd){
    margin-right: 25px;
  }

  .reviews__title {
    font-size: 50px;
    font-weight: 600;
    letter-spacing: -0.035em;
    margin-bottom: 25px;
  }

  .reviews__links{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
  }

  .reviews__link {
    color: #222226;
    font-weight: 600;
    font-size: 20px;
    letter-spacing: -0.035em;
    display: flex;
    align-items: center;
  }

  .reviews__link-img {
    width: 30px;
    height: 30px;
    margin-right: 16px;
  }

  @media (max-width: 768px) {
    .reviews {
      grid-template-columns: repeat(1, 1fr);
    }
    .reviews__item {
      margin-bottom: 16px;
    }
    .reviews__item:nth-child(odd){
      margin-right: 0;
    }

    .reviews__title{
      font-size: 37px;
    }
  }
</style>
<template>
  <Header />
  <main>
    <section class="payment">
      <div class="container">
        <div class="payment__title" v-if="status">Успешно оплачено</div>
        <div class="payment__title" v-else>Платеж не прошел</div>
        <div class="payment-card">
          <div class="payment-main alert-block__main">
            <div class="alert-message" v-if="status">
              Спасибо, что выбрали нас. <br />
              Скоро вам на почту придет чек об оплате.
            </div>
            <div class="alert-message" v-else>
              Упс, кажется произошла ошибка, попробуйте оплатить снова
              <br />
            </div>
          </div>

          <button class="payment-card__btn" @click="GoTo('/')" v-if="status">
            На главную страницу
          </button>
          <button class="payment-card__btn" @click="GoTo('/payment')" v-else>
            Перейти к оплате
          </button>
        </div>
      </div>
    </section>
  </main>
</template>

<style type="text/css">
.alert-message {
  font-weight: 500;
  font-size: 18px;
  color: #222226;
  letter-spacing: -0.035em;
  margin-bottom: 15px;
  line-height: 36px;

  text-align: center;
}

.alert-block__main {
  margin-bottom: 25px;
}
</style>

<script>
/* FYI: This is duplicate code from PaymentConfirm.vue
 * Just for right way support new version like V2
 */
import Header from "@/components/Header/Header";
import { mask } from "vue-the-mask";

export default {
  name: "PaymentV2Confirm",
  directives: { mask },
  data() {
    return {
      status: "",
    };
  },
  mounted() {
    if (this.$route.query.result == "success") {
      this.status = true;
    } else {
      this.status = false;
    }
  },
  methods: {
    GoTo(path) {
      this.$router.push(path);
    },
  },
  watch: {},
  computed: {},
  components: {
    Header,
  },
  created() {},
};
</script>

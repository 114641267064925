<template>
  <swiper
    @swiper="onSwiper"
    :space-between="25"
    :breakpoints="swiperOptions.breakpoints"
    :modules="modules"
    :navigation="{
        prevEl: prev,
        nextEl: next,
    }"
    @slideChange="onSlideChange"
    class="gallery__slider gallery-slider"
  >
    <div ref="prev" class="navig-prev navig-arrow">prev</div>
      <swiper-slide class="gallery-slider__item" v-for="list in galery">
          <img :src="list.get_image">
      </swiper-slide>
    <div ref="next" class="navig-next navig-arrow">next</div>
  </swiper>
</template>

<style type="text/css">
  .gallery-slider__item + .gallery-slider__item {
    margin-left: 0px;
  }

  .gallery__slider .navig-prev {
      right: auto;
      left: 16px;
  }

  .swiper-button-disabled {
    opacity: 0;
  }

  .gallery__slider .navig-arrow {
      position: absolute;
      transform: translateY(50%);
      bottom: 50%;
      right: 16px;
      font-size: 0;
      width: 56px;
      height: 56px;
      background: #7C57FC;
      border: none;
      border-radius: 56px;
      z-index: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      transition: all .3s;
      cursor: pointer;
  }

  .gallery__slider .navig-arrow:hover {
    background: #613AE9 !important;
  }

  .gallery__slider .navig-next:before {
    content: "";
    display: block;
    width: 24px;
    height: 24px;
    background: url('@/assets/icons/next.svg') 50% no-repeat;
    background-size: contain;
  }

  .gallery__slider .navig-prev:before {
    content: "";
    display: block;
    width: 24px;
    height: 24px;
    background: url('@/assets/icons/prev.svg') 50% no-repeat;
    background-size: contain;
  }

</style>
<script>
  import { Scrollbar, Mousewheel, Navigation } from 'swiper';
  import { Swiper, SwiperSlide } from 'swiper/vue';
  import 'swiper/css';
  import { ref, reactive } from 'vue'
  import 'swiper/css/scrollbar';

  import 'swiper/css/navigation';

  export default {
    name: 'MainCarousel',
    data() {
      return {
          swiperOptions: {
            breakpoints: {       
              330: {       
                 slidesPerView: 1.1,
              },          
              400: {       
                 slidesPerView: 1.2,   
              },   
              769: {       
                 slidesPerView: 1.4,  
              },
            }   
         },
         allSliders: 0,        
      }
    },
    components: {
      Swiper,
      SwiperSlide,
    },
    props: {
      galery: {
        type: Array,
        default: []
      },
      currentSlider: {
        type: Number,
        default: 0,
      },
      mouseWheel: {
        type: Boolean,
        default: false,
      },
      cssMode: {
        type: Boolean,
        default: false,
      },
    },
    setup(props, { emit }) {
      const prev = ref(null);
      const next = ref(null);
      const onSwiper = (swiper) => {
      };
      const onSlideChange = (swiper) => {
        emit('active', swiper.activeIndex)
      };
      return {
        onSwiper,
        onSlideChange,
        modules: [Navigation],
        prev,
        next,
      };
    },
    methods: {

    },
    mounted() {
    },
  };
</script>
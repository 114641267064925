<template>
  <Header />
      <main>
         <section class="payment">
            <div class="container">
               <div class="payment__title">Успешно</div>
               <div class="payment-card">
                  <div class="payment-main alert-block__main">
                     <div class="alert-message">Спасибо, что выбрали нас! <br>
                        Вся информация автоматически направлена вам на почту. Скоро с вами свяжется менеджер. </div>
                  </div>
                  <button class="payment-card__btn" @click="GoTo('/')">На главную страницу</button>
               </div>
            </div>
         </section>
      </main>
</template>

<style type="text/css">
.alert-message {
    font-weight: 500;
    font-size: 18px;
    color: #222226;
    letter-spacing: -0.035em;
    margin-bottom: 15px;
    line-height: 36px;

    text-align: center;
}

.alert-block__main {
  margin-bottom: 25px;
}
</style>

<script>
import axios from 'axios'
import moment from 'moment';
import Header from '@/components/Header/Header'
import { mask } from 'vue-the-mask'

export default {
  name: 'SuccessMessage',
  directives: { mask },
  data() {
    return {  

    }
  },
  methods: {
    GoTo(path) {
      this.$router.push(path)
    }
  },
  watch: {

  },
  computed: {
  
  },
  components: {
    Header
  },
  created() {
  },
}
</script>

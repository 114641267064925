<template>
  <Header />
  <main>
    <section class="payment">
      <div class="container">
        <div class="payment__title">Забронировать</div>
        <div class="payment-card">
          <div class="payment-card__main payment-main">
            <div class="payment-card__title">Заполните данные</div>
            <div class="payment-main__grid">
              <div class="payment-main__item full-main__item">
                <input type="text" id="fio" v-model="fio">
                <label for="fio" :class="{ 'active': fio_full }">ФИО участника</label>
              </div>
              <div class="payment-main__item full-main__item">
                <input type="text" v-mask="'##.##.####'" class="date" id="data_uchast" v-model="data_uchast"
                  :class="{ 'date_active': data_uchast_full }" style="letter-spacing: 0px !important;">
                <label for="data_uchast" :class="{ 'active': data_uchast_full }">Дата рождения участника</label>
              </div>
              <div class="payment-main__item full-main__item">
                <input type="text" id="data_parent" v-model="data_parent"
                  :class="{ 'notinclude': data_parent == '' && inclinpts }">
                <label for="data_parent" :class="{ 'active': data_parent_full }
                  ">
                  ФИО родителя / представителя*
                </label>
              </div>
              <div class="payment-main__item payment-main__item_email">
                <input id="phone" v-model="phone" type="tel" v-mask="'+# (###) ###-##-##'"
                  :class="{ 'notinclude': phone == '' && inclinpts }">
                <label for="phone" :class="{ 'active': phone_full }">Телефон*</label>
              </div>
              <div class="payment-main__item payment-main__item_email">
                <input type="text" id="email" v-model="email" :class="{ 'notinclude': email == '' && inclinpts }">
                <label for="email" :class="{ 'active': email_full }">Email*</label>
              </div>
              <div class="payment-main__item payment-main__item_email">
                <input id="dop_phone" v-model="dop_phone" type="tel" v-mask="'+# (###) ###-##-##'">
                <label for="dop_phone" :class="{ 'active': dop_phone_full }">Дополнительный телефон</label>
              </div>
              <div class="payment-main__item payment-main__item_prog">
                <select name="info_title" id="info_title" v-model="info_title">
                  <option value="Рекомендации">
                    Рекомендации
                  </option>
                  <option value="Интернет">
                    Интернет
                  </option>
                  <option value="Другое">
                    Другое
                  </option>
                </select>
                <label for="info_title" :class="{ 'active': info_title_full }">Откуда вы о нас узнали</label>
              </div>
              <div class="payment-main__item payment-main__item_prog">
                <select name="programmName" id="programmName" v-model="programmId"
                  :class="{ 'notinclude': programmId == null && inclinpts }">
                  <option v-for="list in programms_inf" :value="list.id">
                    {{ list.title }}</option>
                </select>
                <label for="programmName" :class="{ 'active': programmId_full }">Программа*</label>
              </div>
              <div class="payment-main__item payment-main__item_date">
                <select name="date" id="date" v-model="data_smeni"
                  :class="{ 'notinclude': data_smeni == '' && inclinpts }">
                  <option v-for="list in smeni"
                    :value="list.number + ' ' + 'смена,' + ' ' + CorDate(list.date_start) + '-' + CorDate(list.date_end, list.number) + ',' + ' ' + list.price + ' ' + 'рублей.'">
                    <span v-if="list.date_start">{{ CorDate(list.date_start) }} - </span> {{ CorDate(list.date_end,
                      list.number) }}
                  </option>
                </select>
                <label for="date" :class="{ 'active': data_smeni_full }" v-if="programmId != 5">Даты заезда*</label>
                <label for="date" :class="{ 'active': data_smeni_full }" v-else>Длительность*</label>
              </div>
              <div class="payment-main__item full-main__item">
                <input type="text" id="primechanie" v-model="primechanie">
                <label for="primechanie" :class="{ 'active': primechanie_full }">Примечание к бронированию</label>
              </div>
            </div>
          </div>
          <div class="payment-card__methods payment-methods">

          </div>
          <button v-if="
            data_parent != '' &&
            phone != '' &&
            email != '' &&
            data_smeni != ''
          " class="payment-card__btn" :disabled="isLoading" @click="ReservePost()">
            Забронировать
          </button>
          <button v-else class="payment-card__btn" style="opacity: 0.7 !important" @click="IncludeInputs()">
            Забронировать
          </button>
        </div>
      </div>
    </section>
  </main>
</template>

<style type="text/css">
input[type=date].date::-webkit-datetime-edit {
  color: transparent;
}

input[type=date].date_active::-webkit-datetime-edit {
  color: #222226 !important;
}

.payment-card__btn:disabled {
  opacity: 0.7;
}

.notinclude {
  border: 1px solid #FF5C5C !important;
}

.payment-main__item input {
  transition: 0s !important;
}
</style>

<script>
import axios from 'axios'
import moment from 'moment';
import Header from '@/components/Header/Header'
import { mask } from 'vue-the-mask'

export default {
  name: 'Reserve',
  directives: { mask },
  data() {
    return {
      programms_inf: [],

      smeni: [],

      fio: "",
      data_uchast: "",
      data_parent: "",
      primechanie: "",
      phone: "",
      email: "",
      dop_phone: "",
      info_title: "Другое",
      programmId: null,
      data_smeni: '',

      smenaId: "",
      programm_info: "",

      inclinpts: false,

      isLoading: false
    }
  },
  methods: {
    async GetSmallInfoProgramms() {
      await axios
        .get(`/api/v1/form_programms/`)
        .then(response => {
          this.programms_inf = response.data
        })
        .catch(error => {
          console.log(error)
        })
    },
    async GetProgramm(pk) {
      await axios
        .get(`/api/v1/programm/get/${pk}/`)
        .then(response => {
          this.programm_info = response.data.title
        })
        .catch(error => {
          console.log(error)
        })
    },
    CorDate(date, days) {
      moment.locale('ru');
      if (date != null) {
        const datasort = moment(String(date)).format('DD MMMM')
        return datasort
      } else {
        if (days != null) {
          return days
        }
      }
    },
    async SmenaActual(value) {
      this.data_smeni = ''

      await axios
        .get(`/api/v1/smeni/${value}/`)
        .then(response => {
          this.smeni = response.data

          if (this.smenaId) {
            let smens = this.smeni.find((item) => item.number === this.smenaId || item.number === parseInt(this.smenaId));
            this.data_smeni = smens.number + ' ' + 'смена,' + ' ' + moment(String(smens.date_start)).format('DD MMMM') + '-' + moment(String(smens.date_end)).format('DD MMMM') + ',' + ' ' + smens.price + ' ' + 'рублей.'
          }
        })
        .catch(error => {
          console.log(error)
        })
    },
    async ReservePost() {
      const dateUpdate = {
        date: null,
      }

      if (this.data_uchast != "") dateUpdate.date = moment(this.data_uchast, 'DD.MM.YYYY').format('YYYY-MM-DD')

      const data = {
        fio: this.fio,
        data_uchast: dateUpdate.date,
        data_parent: this.data_parent,
        primechanie: this.primechanie,
        phone: this.phone,
        email: this.email,
        dop_phone: this.dop_phone,
        info_title: this.info_title,
        programm_info: this.programm_info,
        data_smeni: this.data_smeni
      }

      this.isLoading = true;
      await axios
        .post('/api/v1/reserve/post/', data)
        .then(async () => {
          await this.SendEmail()
          await this.SendEmailClient()

          this.isLoading = false;
          this.$router.push('/success/reserve');
        })
        .catch(error => {
          this.isLoading = false;
          console.log(error)
        });
    },
    async SendEmail() {
      const data = {
        fio: this.fio,
        data_uchast: this.data_uchast,
        data_parent: this.data_parent,
        primechanie: this.primechanie,
        phone: this.phone,
        email: this.email,
        dop_phone: this.dop_phone,
        info_title: this.info_title,
        programm_info: this.programm_info,
        data_smeni: this.data_smeni
      }

      return await axios
        .post('/api/v1/send_email_new_reserve/', data)
        .then(response => {
        })
        .catch(error => {
          console.log(error)
        })
    },
    async SendEmailClient() {
      const data = {
        fio: this.fio,
        data_uchast: this.data_uchast,
        data_parent: this.data_parent,
        phone: this.phone,
        email: this.email,
        dop_phone: this.dop_phone,
        programm_info: this.programm_info,
        data_smeni: this.data_smeni
      }

      return await axios
        .post('/api/v1/send_email_new_reserve_client/', data)
        .then(response => {
        })
        .catch(error => {
          console.log(error)
        })
    },
    IncludeInputs() {
      this.inclinpts = true
    },
  },
  watch: {
    programmId(value) {
      this.programmId = value
      this.SmenaActual(this.programmId)
      this.GetProgramm(this.programmId)
    },
  },
  computed: {
    fio_full() {
      if (this.fio.length > 0) {
        return true;
      } else {
        return false;
      }
    },
    data_uchast_full() {
      if (this.data_uchast.length > 0) {
        return true;
      } else {
        return false;
      }
    },
    data_parent_full() {
      if (this.data_parent.length > 0) {
        return true;
      } else {
        return false;
      }
    },
    phone_full() {
      if (this.phone.length > 0) {
        return true;
      } else {
        return false;
      }
    },
    email_full() {
      if (this.email.length > 0) {
        return true;
      } else {
        return false;
      }
    },
    dop_phone_full() {
      if (this.dop_phone.length > 0) {
        return true;
      } else {
        return false;
      }
    },
    info_title_full() {
      if (this.info_title != null) {
        return true;
      } else {
        return false;
      }
    },
    primechanie_full() {
      if (this.primechanie.length > 0) {
        return true;
      } else {
        return false;
      }
    },
    data_smeni_full() {
      if (this.data_smeni != null) {
        return true;
      } else {
        return false;
      }
    },
    programmId_full() {
      if (this.programmId != null) {
        return true;
      } else {
        return false;
      }
    },
  },
  components: {
    Header
  },
  created() {
    moment.locale('ru');
    this.GetSmallInfoProgramms()
    if (this.$route.query.progId) {
      this.programmId = this.$route.query.progId
    }

    if (this.$route.query.smenaId) {
      this.smenaId = this.$route.query.smenaId
    }
  },
}
</script>

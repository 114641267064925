<template>
      <header class="header header-main" :class="{'active': menumobile}">
         <div class="container">
            <div class="header__row header-main__row">
               <ul class="header__list" :class="{'active': menumobile}">
                  <li>
                     <router-link :to="'/programms/' + new_link" class="header__link">Программы</router-link>
                     <ul class="header__sublist">
                        <li 
                           v-for="(list, index) in programms_inf"
                           :key="index"
                        >
                           <router-link :to="'/programms/' + list.slug">{{ list.title }}</router-link>
                        </li>
                     </ul>
                  </li>
                  <li>
                     <router-link to="/about" class="header__link">О нас</router-link>
                  </li>
                  <li>
                     <li class="header__link">Родителям</li>
                     <ul class="header__sublist">
                        <li 
                           v-for="(item, index) in parents"
                           :key="index"
                        >
                           <router-link :to="item.slug">{{ item.title }}</router-link>
                        </li>
                     </ul>
                  </li>
                  <li>
                     <router-link to="/team" class="header__link">Команда</router-link>
                  </li>
                  <li>
                     <router-link to="/contact" class="header__link">Контакты</router-link>
                  </li>
                  <li class="header__list_adapt">
                     <a href="tel:84956496360" class="header__link">8 (495) 649-63-60</a>
                  </li>
                  <li class="header__list_adapt header__pay">
                     <button @click="GoTo('/payment')">Оплатить</button>
                  </li>
               </ul>
               <div class="header__logo">
                  <router-link to="/">
                     <svg width="59" height="67" viewBox="0 0 59 67" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clip-path="url(#clip0_234_191)">
                           <path
                              d="M0 7.70287L2.44514 21.6477L10.7074 20.1826L10.1066 16.7563L5.36886 17.5961L3.52456 7.07751L0 7.70287Z"
                              fill="#CCFA57" />
                           <path d="M9.09912 6.08854L11.5432 20.0344L14.9936 19.4511L12.5443 5.47791L9.09912 6.08854Z"
                              fill="#CCFA57" />
                           <path
                              d="M27.7157 9.99102C26.6321 3.51678 20.1139 4.13583 20.1139 4.13583L14.1797 5.18684L16.653 19.1559L22.5632 18.1048C22.5632 18.1048 28.7993 16.4653 27.7157 9.99102ZM22.2204 14.6754L19.7126 15.1189L18.4587 8.01827L20.9738 7.57264C20.9738 7.57264 23.7429 7.35403 24.2978 10.6448C24.8526 13.9355 22.2204 14.6754 22.2204 14.6754Z"
                              fill="#CCFA57" />
                           <path
                              d="M27.5674 2.81364L30.0125 16.7585L38.8506 15.2198L38.256 11.8313L32.885 12.7836L32.5287 10.7498L37.3699 9.89117L36.8035 6.6593L32.0345 7.50432L31.7126 5.6682L37.0104 4.72859L36.4002 1.24658L27.5674 2.81364Z"
                              fill="#CCFA57" />
                           <path
                              d="M47.2558 8.3535C47.2558 8.3535 49.2496 6.92412 48.842 3.98654C48.0009 -0.810288 43.0803 0.0620531 43.0803 0.0620531L37.5547 1.04265L40.004 15.0211L43.5108 14.3989L42.724 9.9153L44.0657 9.66937L47.189 13.7105L51.0552 13.0253L47.2558 8.3535ZM44.2893 6.56677L42.2151 6.93673L41.6581 3.79735L43.748 3.4274C43.748 3.4274 45.1639 3.29707 45.4042 4.75273C45.6446 6.20838 44.2893 6.56783 44.2893 6.56783V6.56677Z"
                              fill="#CCFA57" />
                           <path
                              d="M2.90918 24.5979L10.3418 67L29.7911 63.5506L28.3303 55.2139L17.7305 57.0942L11.7587 23.0277L2.90918 24.5979Z"
                              fill="#CCFA57" />
                           <path d="M22.3584 21.1485L13.7144 22.6815L14.9482 29.7198L23.5923 28.1868L22.3584 21.1485Z"
                              fill="#CCFA57" />
                           <path d="M23.9361 30.1481L15.292 31.6812L19.3627 54.9014L28.0067 53.3683L23.9361 30.1481Z"
                              fill="#CCFA57" />
                           <path
                              d="M24.2642 20.8102L31.6978 63.2133L39.1513 61.8911L34.6936 36.4629L54.5421 32.9431L53.2819 25.751L33.3342 29.2887L33.0165 27.4757L52.9632 23.938L51.5661 15.9681L24.2642 20.8102Z"
                              fill="#CCFA57" />
                           <path
                              d="M36.7378 37.8081L40.905 61.5799L59.0001 58.3712L57.6855 50.8733L48.2352 52.5496L47.9039 50.662L57.3554 48.9856L56.2435 42.648L46.8966 44.3065L46.556 42.3632L55.9039 40.7058L54.8329 34.5994L36.7378 37.8081Z"
                              fill="#CCFA57" />
                        </g>
                        <defs>
                           <clipPath id="clip0_234_191">
                              <rect width="59" height="67" fill="white" />
                           </clipPath>
                        </defs>
                     </svg>
                  </router-link>
               </div>
               <div class="header__buttons">
                  <div class="header__phone"><a href="tel:84956496360" class="header__link">8 (495) 649-63-60</a></div>
                  <div class="header__pay header__book">
                     <button @click="GoTo('/reserve')">Забронировать</button>
                  </div>
                  <div class="header__pay">
                     <button @click="GoTo('/payment')">Оплатить</button>
                  </div>
               </div>
               <div class="header__burger" :class="{'active': menumobile}">
                  <svg @click="menumobile = true" class="header__burger_open" width="36" height="36" viewBox="0 0 36 36" fill="none"
                     xmlns="http://www.w3.org/2000/svg">
                     <path d="M31.5 9C22 7.99997 4.5 9 4.5 9" stroke="#CCFA57" stroke-width="2" stroke-linecap="round"
                        stroke-linejoin="round" />
                     <path d="M31.5 18C31.5 18 21 19 13.5 18" stroke="#CCFA57" stroke-width="2" stroke-linecap="round"
                        stroke-linejoin="round" />
                     <path d="M31.5 27C31.5 27 20 26 10.5 27" stroke="#CCFA57" stroke-width="2" stroke-linecap="round"
                        stroke-linejoin="round" />
                  </svg>
                  <svg @click="menumobile = false" class="header__burger_close" width="36" height="36" viewBox="0 0 36 36" fill="none"
                     xmlns="http://www.w3.org/2000/svg">
                     <path d="M27 9C18 15.5 11.5 20.5 9 27" stroke="#7C57FC" stroke-width="2" stroke-linecap="round"
                        stroke-linejoin="round" />
                     <path d="M9 9C16.0294 16.0294 21.5 19 27 27" stroke="#7C57FC" stroke-width="2" stroke-linecap="round"
                        stroke-linejoin="round" />
                  </svg>
               </div>
            </div>
         </div>
      </header>
</template>

<style scoped>

</style>

<script>
import axios from 'axios'
	export default {
		name: 'Header',
    data() {
      return {
        menumobile: false,
        showmenu: false,
        parents: [
            {
               title: 'Образцы документов',
               slug:'/documents'
            },
            {
               title: 'Отзывы',
               slug:'/reviews'
            },
            {
               title: 'Отъезд и приезд',
               slug:'/priezd-i-otezd'
            },
            {
               title: 'Медицина',
               slug:'/meditsina'
            },
        ],
        programms_inf: [],

        new_link: "",
      }
    },
    props: {
      fiol: {
        type: Boolean,
        default: false      
      },
      user__profile: {
        type: Boolean,
        default: false      
      },
    },
    methods: {
        GoTo(path) {
          this.$router.push(path)
        },
        ModalOpenLogout() {
          this.showmodallogout = !this.showmodallogout
        },
        async GetSmallInfoProgramms() {
             await axios
             .get(`/api/v1/small_programms/`)
             .then(response => {
                 this.programms_inf = response.data
                 this.new_link = response.data[0].slug

             })
             .catch(error => {
               console.log(error)
             })
              
        },
    },
    created() {
      this.GetSmallInfoProgramms()
    },
	}
</script>
<template>
  <div class="accordion"
    @click="setActive"
  >
    <div 
      class="accordion__title" 
      :class="{'active': isActive}"
    >
      <span>
        {{ title }}
      </span>
    </div>
    <div 
      class="accordion__subtitle" 
      :class="{'styleObject': isActive}"
      v-html="description"
    >
    </div>
  </div>
</template>

<script>
  export default {
    name: 'Accordion',

    props: {
      title: {
        type: String,
        default: null
      },
      description: {
        type: String,
        default: null
      },

      active: {
        type: Boolean,
        default: false
      }
    },

    data() {
      return {
        isActive: this.active
      }
    },

    methods: {
      setActive() {
        this.isActive = !this.isActive
      }
    }
  }
</script>

<style scoped>

  .accordion {
    padding: 30px;
    position: relative;
    background-color: #fff;
    border-radius: 30px;
  }

  .accordion + .accordion {
    margin-top: 10px;
  }

  .accordion__title {
    cursor: pointer;
    font-weight: 500;
    font-size: 25px;
    letter-spacing: -0.035em;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }

  .accordion__title.active::after {
    background: url("~@/assets/img/icons/minus.svg") center/auto no-repeat;
  }

  .accordion__title.active span::after {
    -webkit-transform: scale(1);
        -ms-transform: scale(1);
            transform: scale(1);
  }

  .accordion__title span {
    display: inline-block;
    height: -webkit-max-content;
    height: -moz-max-content;
    height: max-content;
    position: relative;
  }

  .accordion__title span::after {
    content: "";
    width: 100%;
    height: 11px;
    position: absolute;
    bottom: -11px;
    left: 0;
    background: url("~@/assets/img/icons/faq-line.svg") center/cover no-repeat;
    -webkit-transform: scale(0);
        -ms-transform: scale(0);
            transform: scale(0);
    -webkit-transform-origin: left;
        -ms-transform-origin: left;
            transform-origin: left;
    -webkit-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
  }

  .accordion__title:after {
    content: "";
    display: block;
    -webkit-box-flex: 0;
        -ms-flex: 0 0 27px;
            flex: 0 0 27px;
    height: 27px;
    -webkit-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
    background: url("~@/assets/img/icons/plus.svg") center/auto no-repeat;
    margin-left: 50px;
  }

  .accordion__subtitle {
    margin-top: 30px;
    font-size: 16px;
    color: #8C8A92;
    display: none;
    padding-right: 20px;
    line-height: 1.375;
    letter-spacing: 0px !important;
    max-width: 90%;
  }

  .styleObject {
   transition: 0.3s;
   display: block !important;
}

  .accordion__subtitle a {
    color: #7C57FC !important;
  }

  @media (max-width: 768px) {
    .accordion {
      padding: calc(15px + 15 * (100vw - 375px) / 393);
    }

    .accordion__title {
      font-size: calc(16px + 9 * (100vw - 375px) / 393);
      line-height: 1.375;
    }

    .accordion__subtitle {
      font-size: calc(14px + 2 * (100vw - 375px) / 393);
      margin-top: calc(20px + 10 * (100vw - 375px) / 393);
    }
  }

  @media (max-width: 375px) {
    .accordion {
      padding: 15px;
    }

    .accordion__title {
      font-size: 16px;
    }

    .accordion__subtitle {
      font-size: 14px;
      margin-top: 20px;
    }
  }
</style>
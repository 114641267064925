<template>
  <swiper
    :slides-per-view="1"
    :loop="true"
    :autoplay="autoplay"
    :modules="[EffectFade]"
    effect="fade"
    @swiper="onSwiper"
    @slideChange="onSlideChange"
    class="hero-slider"
  >
    <swiper-slide
      class="hero-slider__image darkened"
      v-for="list in soloSlider"
      :key="list.get_image"
    >
      <img :src="getPrevieImage(list.get_image)" alt="" loading="lazy" />
    </swiper-slide>
  </swiper>
</template>

<style type="text/css">
@media (max-width: 576px) {
  .hero-slider__image {
    height: 754px;
  }
}

.hero-slider__image {
  position: relative;
}

.hero-slider__image.darkened::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  z-index: 1;
}
</style>
<script>
import SwiperCore, { Navigation, Autoplay, EffectFade } from "swiper";
import { Swiper, SwiperSlide } from "swiper/vue";
SwiperCore.use(Autoplay, EffectFade);

import "swiper/css";
import "swiper/css/effect-fade";

export default {
  name: "SoloSlider",
  data() {
    return {
      autoplay: {
        delay: 3000,
      },
      fadeEffect: {
        crossFade: true,
      },
    };
  },
  methods: {
    getPrevieImage(image) {
      return `${process.env.VUE_APP_API_URL || ""}${image}`;
    },
  },
  components: {
    Swiper,
    SwiperSlide,
  },
  props: {
    soloSlider: {
      type: Array,
      default: [],
    },
  },
  setup() {
    const onSwiper = (swiper) => {};
    const onSlideChange = () => {};
    return {
      onSwiper,
      onSlideChange,
      EffectFade,
    };
  },
};
</script>
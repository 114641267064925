<template>
  <Header />
  <main>
    <section class="aboutPage__hero aboutPage-hero">
      <div class="container">
        <div class="aboutPage-hero__row">
          <h1 class="aboutPage-hero__title hero__title">
            <span>Отъезд и </span>
            <span>приезд</span>
          </h1>
        </div>
        <div class="faq__card faq__card-with-map">
          <div class="faq__card-title">
            Для лагерей&nbsp;<strong>Галилео Кидс,</strong> <strong>Лидер – Успешный Старт,</strong> <strong>Лидер – Шаг Вперед,</strong> <strong>Лидер в кадре</strong><br/>
            <br/>
            Отправка и приезд происходит возле станции метро Кузьминки. <br/>
            Ниже даты и время отъезда/приезда для летних лагерей 2024:
          </div>
          <div class="faq__card-map">
            <iframe class="faq__card-map-item" src="https://yandex.ru/map-widget/v1/?um=constructor%3Afe54af142583325d6c213a51beb0e16c9708ffa73021199f03060e78fde05c05&amp;source=constructor" width="550" height="250" frameborder="0"></iframe>
          </div>
        </div>
        <div class="info-hero__docs accordion__list">
          <Accordion
            v-for="(item, idx) in schedule"
            :key="idx"
            :title="item.title"
            :description="item.description"
          />
        </div>
        <div class="faq__card faq__card-with-map">
          <div class="faq__card-title">
            Для лагеря <strong>Horse Paradise:</strong><br/>
            <br/>
            Отправка и приезд происходят по адресу 1-я Парковая, 1/51.<br/>
            Ниже даты и время отъезда/приезда для летних лагерей 2024:<br/>
            <br/>
            За неделю до смены мы сделаем рассылку на почту, где пришлем с памятку и номер руководителя смены.
          </div>
          <div class="faq__card-map">
            <iframe class="faq__card-map-item" src="https://yandex.ru/map-widget/v1/?um=constructor%3Aca03cdd28abeddbdbc4271d72b2faa5dc5c3135e18bad4e87b826a3ebeb1231e&amp;source=constructor" width="550" height="250" frameborder="0"></iframe>
          </div>
        </div>
      </div>
    </section>
  </main>
 
</template>

<script>
  import Header from '@/components/Header/Header'
  import Accordion from '@/components/ui/Accordion'

  export default {
    name: 'PriezdOtezd',

    components: {
      Header,
      Accordion
    },

    data() {
      return {
        schedule: [
          {
            title: '1 смена',
            description: '01.06.2024. Сбор 10:00, отправка 10:30<br/>14 06.2024. Прибытие в Кузьминки ориентировочно в 14:00.', 
            active: false
          },
          {
            title: '2 смена',
            description: '16.06.2024. Сбор 10:00, отправка 10:30<br/>29 06.2024. Прибытие в Кузьминки ориентировочно в 14:00.', 
            active: false
          },
          {
            title: '3 смена',
            description: '01.07.2024. Сбор 10:00, отправка 10:30<br/>14 07.2024. Прибытие в Кузьминки ориентировочно в 14:00.', 
            active: false
          },
          {
            title: '4 смена',
            description: '16.07.2024. Сбор 10:00, отправка 10:30<br/>29 07.2024. Прибытие в Кузьминки ориентировочно в 14:00.', 
            active: false
          },
          {
            title: '5 смена',
            description: '31.07.2024. Сбор 10:00, отправка 10:30<br/>13 08.2024. Прибытие в Кузьминки ориентировочно в 14:00.', 
            active: false
          },
          {
            title: '6 смена',
            description: '15.08.2024. Сбор 10:00, отправка 10:30<br/>28 08.2024. Прибытие в Кузьминки ориентировочно в 14:00.', 
            active: false
          },
        ]
      }
    }
  }
</script>

<style>
  .accordion__list {
    margin: 20px 0 50px;
  }

  .faq__card-with-map {
    display: grid;
    grid-template-columns: 1fr auto;
  }

  .faq__card-map {
    border-radius: 15px;
    overflow: hidden;
    margin-left: 15px;
  }

  .faq__card-map-item {
    border-radius: 15px;
  }

  @media (max-width: 1024px) {
    .accordion__list {
      margin: 20px 0;
    }
  }

  @media (max-width: 767px) {
    .faq__card-with-map {
      display: grid;
      grid-template-columns: 1fr;
    }

    .faq__card-map {
      margin-left: 0;
      margin-top: 15px;
    }
  }
</style>
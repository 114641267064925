import { createApp } from "vue";
import VueProgressBar from "@aacassandra/vue3-progressbar";
import VueYandexMetrika from "vue-yandex-metrika";

import App from "./App.vue";
import router from "./router";
import store from "./store";
import axios from "axios";

import "aos/dist/aos.css";
import "animate.css";

const options = {
  color: "#7C57FC",
  failedColor: "#000",
  thickness: "3px",
  transition: {
    speed: "0.2s",
    opacity: "0.6s",
    termination: 300,
  },
  autoRevert: true,
  location: "top",
  inverse: false,
};

console.log('Base URL: ', process.env.VUE_APP_API_URL);
axios.defaults.baseURL = process.env.VUE_APP_API_URL;

createApp(App)
  .use(VueYandexMetrika, {
    id: 93390828,
    router: router,
    env: process.env.NODE_ENV,
  })
  .use(VueProgressBar, options)
  .use(store)
  .use(router, axios)
  .mount("#app");

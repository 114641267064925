<template>
  <Header />
      <main>
         <section class="aboutPage__hero aboutPage-hero">
            <div class="container">
               <div class="aboutPage-hero__row">
                  <h1 class="aboutPage-hero__title hero__title">
                     <span>О компании</span>
                     <span>Лидерлайф</span>
                  </h1>
                  <div class="aboutPage-hero__subtitle">О нашей миссии, целях и приоритетах</div>
               </div>
            </div>
<!--             <div class="marquee-wrapper">
               <div class="marquee-infinite">
                  <div class="marquee-track">
                     <span>Наша команда – это более 50 заряженных людей, которые искренне любят работать с детьми и горят своим делом.</span>
                     <span>Каждый из них – студент или выпускник ведущего ВУЗа Москвы, прошедший подготовку в нашей школе вожатых.
                     Знакомьтесь!
                     </span>
                     <span>Наша команда – это более 50 заряженных людей, которые искренне любят работать с детьми и горят своим делом.</span>
                     <span>Каждый из них – студент или выпускник ведущего ВУЗа Москвы, прошедший подготовку в нашей школе вожатых.
                     Знакомьтесь!
                     </span>
                  </div>
                  <div class="marquee-track">
                     <span>Наша команда – это более 50 заряженных людей, которые искренне любят работать с детьми и горят своим делом.</span>
                     <span>Каждый из них – студент или выпускник ведущего ВУЗа Москвы, прошедший подготовку в нашей школе вожатых.
                     Знакомьтесь!
                     </span>
                     <span>Наша команда – это более 50 заряженных людей, которые искренне любят работать с детьми и горят своим делом.</span>
                     <span>Каждый из них – студент или выпускник ведущего ВУЗа Москвы, прошедший подготовку в нашей школе вожатых.
                     Знакомьтесь!
                     </span>
                  </div>
               </div>
            </div> -->
         </section>
         <section class="aboutPage__cards aboutPage-cards">
            <div class="container">
               <div class="aboutPage__column">
                  <div class="about__card about-card">
                     <div class="about-card__image">
                        <img src="@/assets/img/about/card1.png" alt="">
                     </div>
                     <div class="about-card__row">
                        <div class="about-card__title">История</div>
                        <div class="about-card__text">
                           <p>Лидерлайф появился в 2008 году. До этого мы проводили смены на базе других лагерей, но впоследствии решили, что пора создать свой лагерь, который будет полностью соответствовать нашим ценностям.
                           За эти 15 лет мы провели более 300 различных смен, в том числе и в других городах: Санкт-Петербурге, Калининграде, Казани, Нижнем Новгороде, Крыму и Ярославле. В нашем лагере побывало около 6000 детей, некоторые из которых сейчас продолжают ездить в качестве вожатых.
                           </p>
                        </div>
                     </div>
                  </div>
                  <div class="about__card about-card">
                     <div class="about-card__image">
                        <img src="@/assets/img/about/card2.png" alt="">
                     </div>
                     <div class="about-card__row">
                        <div class="about-card__title">Миссия и цель</div>
                        <div class="about-card__text">
                           <p>Мы стремимся организовывать программы, которые вдохновляют участников, позволяют им получить новый опыт и стать более коммуникабельными и открытыми.</p>
                        </div>
                     </div>
                  </div>
                  <div class="about__card about-card">
                     <div class="about-card__image">
                        <img src="@/assets/img/about/card3.png" alt="">
                     </div>
                     <div class="about-card__row">
                        <div class="about-card__title">Открытость компании</div>
                        <div class="about-card__text">
                           <p>Мы стремимся идти в ногу со временем и каждый год адаптируем наши программы в связи с трендами и запросами родителей и участников. Наша команда всегда открыта ко всем вопросам и комментариям.</p>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </section>
         <section class="aboutPage-values">
            <div class="container">
               <div class="aboutPage-values__title title">Ценности</div>
               <div class="aboutPage-values__row">
                  <div class="aboutPage-values__column">
                     <div class="aboutPage-values__card values-card wow bounceInUp">
                        <div class="values-card__title">Безопасность участников
                        </div>
                        <div class="values-card__text">Наш приоритет</div>
                     </div>
                     <div class="aboutPage-values__card values-card wow bounceInUp">
                        <div class="values-card__title">Команда

                        </div>
                        <div class="values-card__text"> Наши вожатые — лицо лагеря, где каждый прошел тщательную
                           подготовку в нашей школе вожатых и
                           умеет не только найти подход к ребятам, но и понимает, что на нём лежит большая
                           ответственность</div>
                     </div>
                     <div class="aboutPage-values__card values-card wow bounceInUp">
                        <div class="values-card__title">Теплая семейная атмосфера
                        </div>
                        <div class="values-card__text">Обстановка, в которой хочется раскрываться и развиваться</div>
                     </div>
                  </div>
                  <div class="aboutPage-values__column">
                     <div class="aboutPage-values__card values-card wow bounceInUp">
                        <div class="values-card__title">Общение
                        </div>
                        <div class="values-card__text">Наши участники поддерживают связь после смены, а мы стараемся
                           способствовать этому и
                           подчеркиваем, что диалог – это главное в любой ситуации</div>
                     </div>
                     <div class="aboutPage-values__card values-card wow bounceInUp">
                        <div class="values-card__title">Воспитание нового поколения созидателей
                        </div>
                        <div class="values-card__text">
                           Мы гордимся тем, какой вклад вносим в формирование каждого и какие ценности транслируем</div>
                     </div>
                  </div>
                  <div class="aboutPage-values__column">
                     <div class="aboutPage-values__card values-card wow bounceInUp">
                        <div class="values-card__title">Подход
                        </div>
                        <div class="values-card__text">Мы вкладываем много сил в то, чтобы найти подход к каждому
                           участнику, заинтересовать его и
                           создать комфортную среду. Еще мы очень любим общаться с участниками на самые разные темы
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </section>
         <section class="aboutSection statistics" ref="stat">
            <div class="container">
               <div class="statistics__title title">Статистика</div>
               <div class="statistics__grid">
                  <div class="statistics__item" id="15" v-for="list, index in array">
                     <div class="statistics__item_big">
                        <span id="statsCount">{{ list.count }}</span>
                        <span v-if="index == 0">лет</span>
                        <span v-else>%</span>
                     </div>
                     <p class="statistics__item_small">{{ list.disc }}</p>
                  </div>
               </div>
            </div>
         </section>
         <section class="aboutSection contacts">
            <div class="container">
               <div class="contacts__card wow swing">
                  <div class="voskArrow_fone">
                     <img src="@/assets/img/about/voskArrow.svg">
                  </div>
                  Вы можете звонить в любой день недели — даже если уже не рабочий день, мы все равно постараемся
                  подойти к телефону
               </div>
               <div class="contacts__title title">Контакты</div>
               <div class="contacts__grid">
                  <div class="contacts__item">
                     ООО «Лидерлайф Турс»
                  </div>
                  <div class="contacts__item">
                     <a href="tel:+74956496360">+7 (495) 649-63-60</a>
                  </div>
                  <div class="contacts__item">
                     <a href="mailto:camp@liderlife.ru">camp@liderlife.ru</a>
                  </div>
                  <div class="contacts__item">
                     Москва, Походный проезд, д. 4, корп. 1 (БЦ Тушино). 4 этаж, офис 409-Б.М. Тушинская (МЦД
                     Трикотажная)
                  </div>
               </div>
               <div class="contacts__social contacts-social">
                  <div class="contacts-social__item">
                     <a href="https://vk.com/liderlifepub" target="_blank">
                        <svg width="28" height="18" viewBox="0 0 28 18" xmlns="http://www.w3.org/2000/svg">
                           <path
                              d="M26.9008 17.6134H23.8442C22.6869 17.6134 22.3292 16.6761 20.2503 14.53C18.4393 12.744 17.6383 12.5039 17.1937 12.5039C16.5694 12.5039 16.3913 12.6854 16.3913 13.5627V16.3746C16.3913 17.1318 16.1542 17.5848 14.1946 17.5848C10.2416 17.3147 6.84408 15.1186 4.87179 11.9181L4.84234 11.8681C2.55303 8.9719 0.865504 5.48131 0.0308606 1.66353L0 1.49636C0 1.04343 0.178151 0.61907 1.03945 0.61907H4.09607C4.86759 0.61907 5.16497 0.981988 5.46376 1.82784C6.97594 6.27431 9.50091 10.175 10.5404 10.175C10.9261 10.175 11.1043 9.9935 11.1043 8.99619V4.39685C10.985 2.27935 9.88667 2.09932 9.88667 1.34348C9.8993 0.939123 10.2247 0.616211 10.6231 0.616211C10.6357 0.616211 10.647 0.616212 10.6596 0.617641H15.4683C16.122 0.617641 16.359 0.980558 16.359 1.7664V7.96745C16.359 8.63184 16.6564 8.87474 16.8346 8.87474C17.2203 8.87474 17.5486 8.63184 18.2598 7.90744C19.7383 6.06856 21.0022 3.96534 21.9659 1.69782L22.0304 1.52636C22.2338 1.00771 22.722 0.647645 23.2929 0.647645C23.3195 0.647645 23.3448 0.649073 23.3714 0.650502H23.3672H26.4238C27.3441 0.650502 27.5531 1.13201 27.3441 1.79927C26.118 4.54973 24.7405 6.91298 23.1442 9.10192L23.2171 8.99619C22.8917 9.54057 22.7725 9.78204 23.2171 10.3893C23.5426 10.8422 24.6129 11.7795 25.3255 12.6268C26.3565 13.6655 27.2206 14.8743 27.8729 16.2088L27.908 16.2874C28.2054 17.1618 27.7593 17.6162 26.898 17.6162L26.9008 17.6134Z"
                               />
                        </svg>
                     </a>
                   </div>
                   <div class="contacts-social__item">
                     <a href="https://t.me/+bEJ7tXoDFgAyOGJi" target="_blank">
                        <svg width="24" height="21" viewBox="0 0 24 21" xmlns="http://www.w3.org/2000/svg">
                           <path
                              d="M23.4325 2.47095L19.9777 19.4157C19.714 20.6201 19.0283 20.8939 18.0525 20.3464L12.778 16.295L10.1408 18.8408C10.0166 19.0092 9.85701 19.146 9.67424 19.241C9.49148 19.3357 9.29026 19.3861 9.08587 19.3883L9.45509 13.9134L19.2129 4.74302C19.6612 4.35978 19.2129 4.14078 18.5799 4.52402L6.60687 12.3257L1.33238 10.6285C0.198369 10.2726 0.171995 9.4514 1.56973 8.90392L21.9029 0.691618C22.905 0.3905 23.7489 0.992734 23.4325 2.47095Z"
                        />
                        </svg>
                     </a>
                  </div>
                  <div class="contacts-social__item">
                     <a href="https://www.youtube.com/user/liderlifevideo" target="_blank">
                        <svg width="26" height="26" viewBox="0 0 26 26" fill="#fff" xmlns="http://www.w3.org/2000/svg">
                           <path d="M24.4177 6.95498C24.289 6.44084 24.0269 5.96977 23.6579 5.58934C23.2888 5.20891 22.826 4.9326 22.316 4.78831C20.4527 4.33331 12.9993 4.33331 12.9993 4.33331C12.9993 4.33331 5.54598 4.33331 3.68265 4.83165C3.17267 4.97593 2.70979 5.25224 2.34078 5.63267C1.97176 6.0131 1.70967 6.48417 1.58098 6.99831C1.23997 8.88933 1.07316 10.8076 1.08265 12.7291C1.07049 14.6651 1.23732 16.5981 1.58098 18.5033C1.72286 19.0015 1.99082 19.4546 2.35897 19.819C2.72713 20.1834 3.18304 20.4466 3.68265 20.5833C5.54598 21.0816 12.9993 21.0816 12.9993 21.0816C12.9993 21.0816 20.4527 21.0816 22.316 20.5833C22.826 20.439 23.2888 20.1627 23.6579 19.7823C24.0269 19.4019 24.289 18.9308 24.4177 18.4166C24.756 16.5399 24.9228 14.6362 24.916 12.7291C24.9281 10.7932 24.7613 8.86024 24.4177 6.95498Z" stroke="#7C57FC" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                           <path d="M10.5625 16.2716L16.7917 12.7291L10.5625 9.18665V16.2716Z" stroke="#7C57FC" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                     </a>
                  </div>
<!--                   <div class="contacts-social__item">
                     <a href="https://t.me/+rYn9DavI6s80OTgy" target="_blank">
                        <svg width="24" height="21" viewBox="0 0 24 21" xmlns="http://www.w3.org/2000/svg">
                           <path
                              d="M23.4325 2.47095L19.9777 19.4157C19.714 20.6201 19.0283 20.8939 18.0525 20.3464L12.778 16.295L10.1408 18.8408C10.0166 19.0092 9.85701 19.146 9.67424 19.241C9.49148 19.3357 9.29026 19.3861 9.08587 19.3883L9.45509 13.9134L19.2129 4.74302C19.6612 4.35978 19.2129 4.14078 18.5799 4.52402L6.60687 12.3257L1.33238 10.6285C0.198369 10.2726 0.171995 9.4514 1.56973 8.90392L21.9029 0.691618C22.905 0.3905 23.7489 0.992734 23.4325 2.47095Z"
                        />
                        </svg>
                     </a>
                  </div> -->
<!--                   <div class="contacts-social__item">
                     <a href="">
                        <svg width="22" height="23" viewBox="0 0 22 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                           <path
                              d="M16.2783 13.258C16.2355 13.2374 14.6316 12.4477 14.3466 12.3451C14.2303 12.3033 14.1056 12.2625 13.9731 12.2625C13.7565 12.2625 13.5746 12.3704 13.4328 12.5824C13.2726 12.8206 12.7876 13.3875 12.6378 13.5569C12.6182 13.5792 12.5915 13.6059 12.5755 13.6059C12.5611 13.6059 12.3129 13.5037 12.2378 13.4711C10.5173 12.7237 9.21134 10.9265 9.03227 10.6234C9.0067 10.5799 9.00563 10.5601 9.00542 10.5601C9.0117 10.537 9.06956 10.479 9.09942 10.4491C9.18677 10.3627 9.28142 10.2487 9.37299 10.1385C9.41634 10.0863 9.45977 10.034 9.50242 9.98473C9.63527 9.83016 9.69442 9.71016 9.76299 9.57116L9.79892 9.49895C9.96634 9.16631 9.82334 8.88559 9.77713 8.79495C9.7392 8.71909 9.06199 7.08466 8.98999 6.91295C8.81684 6.49859 8.58806 6.30566 8.27013 6.30566C8.24063 6.30566 8.27013 6.30566 8.14642 6.31088C7.99577 6.31724 7.17542 6.42524 6.8127 6.65388C6.42806 6.89638 5.77734 7.66938 5.77734 9.02881C5.77734 10.2523 6.55377 11.4075 6.88713 11.8469C6.89542 11.8579 6.91063 11.8804 6.9327 11.9127C8.20934 13.7772 9.80084 15.1589 11.4142 15.8034C12.9674 16.4238 13.7029 16.4955 14.1211 16.4955H14.1211C14.2968 16.4955 14.4375 16.4817 14.5616 16.4695L14.6403 16.462C15.1768 16.4144 16.356 15.8034 16.6242 15.0581C16.8355 14.471 16.8912 13.8296 16.7506 13.5968C16.6543 13.4385 16.4884 13.3589 16.2783 13.258Z"
                              fill="#7C57FC" />
                           <path
                              d="M11.1953 0.616211C5.23698 0.616211 0.389555 5.42721 0.389555 11.3407C0.389555 13.2534 0.901412 15.1256 1.87105 16.7641L0.0151263 22.2388C-0.0194452 22.3409 0.00626912 22.4537 0.0817691 22.5306C0.136269 22.5862 0.210198 22.6162 0.285698 22.6162C0.314626 22.6162 0.343769 22.6119 0.372198 22.6028L6.08077 20.7888C7.64291 21.6234 9.40848 22.064 11.1953 22.064C17.1531 22.0641 22 17.2536 22 11.3407C22 5.42721 17.1531 0.616211 11.1953 0.616211ZM11.1953 19.8301C9.51391 19.8301 7.88541 19.3446 6.48555 18.426C6.43848 18.3951 6.38384 18.3792 6.32884 18.3792C6.29977 18.3792 6.27063 18.3836 6.24227 18.3926L3.38263 19.3016L4.30577 16.5781C4.33563 16.49 4.3207 16.3928 4.2657 16.3176C3.1997 14.8611 2.6362 13.1401 2.6362 11.3407C2.6362 6.65907 6.47577 2.85021 11.1952 2.85021C15.9141 2.85021 19.7532 6.65907 19.7532 11.3407C19.7533 16.0218 15.9142 19.8301 11.1953 19.8301Z"
                              fill="#7C57FC" />
                        </svg>
                     </a>
                  </div> -->
               </div>
               <div class="pos-arrow_fone">
                  <div class="posArrow_fone">
                     <img src="@/assets/img/about/arrowFone.svg">
                  </div>
               </div>
               <div class="contacts-column">
                  <div class="contacts-column__item">
                     Вы можете звонить в любой день недели — даже если уже нерабочий день, мы все равно постараемся
                     подойти к телефону
                  </div>
                  <div class="contacts-column__item">Напоминаем, что на любую программу Вы можете заказать бесплатную
                     курьерскую доставку полного комплекта документов для поездки в лагерь (путёвка/ваучер, договор и
                     памятку).</div>
                  <!-- <button class="contacts-column__item">
                     Заказать курьерскую доставку
                  </button> -->
               </div>
            </div>
         </section>
         <section class="aboutSection map">
            <iframe src="https://yandex.ru/map-widget/v1/?um=constructor%3Ae3a25c898dee5d467d9c819545718c5ce1c34410d11bb3dd505a9f195e233b7a&amp;source=constructor" width="100%" height="400" frameborder="0"></iframe>
         </section>
      </main>
</template>

<style type="text/css">

</style>

<script>
import axios from 'axios'
import Header from '@/components/Header/Header'
import {WOW} from 'wowjs';

export default {
  name: 'About',
  data() {
    return {  
      array: [
         {
            count: 15,
            disc: 'Делаем лагеря',
         },
         {
            count: 70,
            disc: 'Наших вожатых — выросшие участники. Это подчеркивает, что мы уверены в транслировании правильных ценностей на сменах',
         },
         {
            count: 100,
            disc: 'Потраченных сил на каждую смену',
         },
         {
            count: 200,
            disc: 'Отдачи от участников',
         },
         {
            count: 83,
            disc: 'Оригинальных мероприятий',
         },
         {
            count: 71,
            disc: 'Повторной покупки путевки',
         },
      ],
      done: false,
    }
  },
  methods: {
      onScroll(e) {
         this.windowTop = window.top.scrollY 
         this.block_scroll = (this.$refs?.stat?.offsetTop - 300)
         if(this.block_scroll <= this.windowTop) {
            this.done = true
         }
      },
      showValue(index, maxValue) {
         let value = 0;
         let allTime = 1800;
         let intervalTime = allTime / (maxValue);

         let timerId = setInterval(() => {
            value++;
            
            if (value == maxValue) {
               clearInterval(timerId)
            }
            this.array[index].count = value
         }, intervalTime)

      },
      GetValue() {
         for (let i = 0; i < this.array.length; i++) {
            this.showValue(i, this.array[i].count)
         }
      },
  },
  components: {
    Header
  },
  mounted() {
    window.addEventListener("scroll", this.onScroll)
    new WOW().init();
  },
  beforeDestroy() {
     window.removeEventListener("scroll", this.onScroll)
  },
  watch: {
      done() {
         if (this.done) {
            this.GetValue()
         }
      },
  }
}
</script>

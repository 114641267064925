<template>
  <Header />

  <section v-if="templateCur == 0">
     <LiderStart :programm="programm" :template="templateObj" :info="info" />
  </section>

  <section v-if="templateCur == 1">
     <LiderStep :programm="programm" :template="templateObj" :info="info" />
  </section>
  
  <section v-if="templateCur == 2">
     <Kidsee :programm="programm" :template="templateObj" :info="info" />
  </section>

  <section v-if="templateCur == 3">
     <LiderVKadre :programm="programm" :template="templateObj" :info="info" />
  </section>

  <section v-if="templateCur == 5">
     <LiderHourse :programm="programm" :template="templateObj" :info="info" />
  </section>

  <section v-if="templateCur == 4">
     <Vipysknoy :programm="programm" :template="templateObj" :info="info" />
  </section>

</template>

<style type="text/css">

</style>

<script>
import axios from 'axios'
import Header from '@/components/Header/Header'
import moment from 'moment';

import LiderHourse from '@/components/Programms/LiderHourse'
import LiderVKadre from '@/components/Programms/LiderVKadre'
import LiderStart from '@/components/Programms/LiderStart'
import LiderStep from '@/components/Programms/LiderStep'
import Kidsee from '@/components/Programms/Kidsee'
import Vipysknoy from '@/components/Programms/Vipysknoy'


export default {
  name: 'Programms',
  data() {
    return {  
       info: [
     
       ],
       programm: {},
       templateObj: {},
       smena: null,
       programSlug: "",

       page: null,

       templateCur: 0,
    }
  },
  mounted() {
      this.page = this.$route.path
      
  },
  methods: {
     async GetProgram() {
         this.programSlug = this.$route.params.program_slug

         await axios
             .get(`/api/v1/slg/programm/${this.programSlug}/`)
             .then(response => {
                 this.programm = response.data
                 this.GetTemplate(response.data.id)
                 document.title = this.programm.title
             })
             .catch(error => {
                 console.log(error)
             })
     },
     async GetTemplate(value) {
         await axios
             .get(`/api/v1/get/template/${value}/`)
             .then(response => {
                this.templateObj = response.data
                this.templateCur = response.data.template
                this.info = response.data.bquestions
             })
             .catch(error => {
                 console.log(error)
             })
     },
  },
  watch: {
     $route() {
         this.page = this.$route.path
         this.GetProgram()
     },
  },
  components: {
    Header,
    LiderStart,
    LiderStep,
    Kidsee,
    LiderVKadre,
    LiderHourse,
    Vipysknoy
  },
  created() {
     this.GetProgram()
  }
}
</script>

<template>
  <Header />
  <main>
    <section class="payment payment--v2">
      <div class="container">
        <div class="payment__title">Оплата</div>
        <div
          id="yookassa-payment-form"
          :class="{ hidden: !isPaymentProcessing }"
        ></div>
        <form
          method="POST"
          class="payment-card"
          :class="{ hidden: isPaymentProcessing }"
          ref="form"
          @submit.prevent="Payment"
        >
          <div class="payment-card__main payment-main payment-card__main--v2">
            <div class="payment-card__title">Ваши данные</div>
            <div class="payment-main__grid">
              <div class="payment-main__item payment-main__item_amount">
                <input
                  type="number"
                  name="sum"
                  v-model="price"
                  min="1"
                  max="1000000"
                />
                <label for="sum" :class="{ active: price_full }"
                  >Сумма оплаты</label
                >
              </div>
              <div class="payment-main__item payment-main__item_prog">
                <select
                  name="programmName"
                  id="programmName"
                  v-model="programmId"
                >
                  <option
                    v-for="list in programms_inf"
                    :value="list.id"
                    v-bind:key="list.id"
                  >
                    {{ list.title }}
                  </option>
                </select>
                <label for="programmName" :class="{ active: programmId_full }"
                  >Программа</label
                >
              </div>
              <div class="payment-main__item payment-main__item_date">
                <select name="date" id="date" v-model="data_smeni">
                  <option
                    v-for="list in smeni"
                    :value="
                      list.number +
                      ' ' +
                      'смена,' +
                      ' ' +
                      CorDate(list.date_start) +
                      '-' +
                      CorDate(list.date_end, list.number) +
                      ',' +
                      ' ' +
                      list.price +
                      ' ' +
                      'рублей.'
                    "
                    v-bind:key="list.number"
                  >
                    <span v-if="list.date_start"
                      >{{ CorDate(list.date_start) }} -
                    </span>
                    {{ CorDate(list.date_end, list.number) }}
                  </option>
                </select>
                <label
                  for="date"
                  :class="{ active: data_smeni_full }"
                  v-if="programmId != 5"
                  >Даты заезда</label
                >
                <label for="date" :class="{ active: data_smeni_full }" v-else
                  >Длительность</label
                >
              </div>
              <div class="payment-main__item payment-main__item_surname">
                <input type="text" id="surname" v-model="surname" />
                <label for="surname" :class="{ active: surname_full }"
                  >Фамилия</label
                >
              </div>
              <div class="payment-main__item payment-main__item_name">
                <input type="text" id="name" v-model="name" />
                <label for="name" :class="{ active: name_full }">Имя</label>
              </div>
              <div class="payment-main__item payment-main__item_email">
                <input
                  name="client_phone"
                  v-model="phone"
                  type="tel"
                  v-mask="'+# (###) ###-##-##'"
                />
                <label for="client_phone" :class="{ active: phone_full }"
                  >Телефон</label
                >
              </div>
              <div class="payment-main__item payment-main__item_email">
                <input
                  name="client_email"
                  type="text"
                  id="email"
                  v-model="email"
                />
                <label for="client_email" :class="{ active: email_full }"
                  >Email</label
                >
              </div>
            </div>
          </div>
          <button
            type="submit"
            class="payment-card__btn"
            :disabled="
              'disabled'
                ? price == '' ||
                  programms_inf == '' ||
                  name == '' ||
                  surname == '' ||
                  email == '' ||
                  phone == ''
                : ''
            "
          >
            Оплатить
            <span v-if="price">{{ price }} ₽</span>
          </button>
        </form>
      </div>
    </section>
  </main>
</template>

<style type="text/css">
</style>

<script>
import axios from "axios";
import Header from "@/components/Header/Header";
import { mask } from "vue-the-mask";
import moment from "moment";
import * as bulmaToast from "bulma-toast";

export default {
  name: "PaymentV2",
  directives: { mask },
  data() {
    return {
      programms_inf: [],

      smeni: [],

      name: "",
      surname: "",
      phone: "",
      email: "",
      programmId: null,
      data_smeni: null,
      price: "",

      programm_info: "",
      fiofull: "",

      callback: "https://liderlife.ru/payment/confirm",
      isPaymentProcessing: false,
    };
  },
  mounted() {
    let checkoutJsWidget = document.createElement("script");
    checkoutJsWidget.setAttribute(
      "src",
      "https://yookassa.ru/checkout-widget/v1/checkout-widget.js"
    );
    checkoutJsWidget.setAttribute("async", "true");
    document.head.appendChild(checkoutJsWidget);
  },
  methods: {
    async GetSmallInfoProgramms() {
      await axios
        .get(`/api/v1/form_programms/`)
        .then((response) => {
          this.programms_inf = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async GetProgramm(pk) {
      await axios
        .get(`/api/v1/programm/get/${pk}/`)
        .then((response) => {
          this.programm_info = response.data.title;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    CorDate(date, days) {
      moment.locale("ru");
      if (date != null) {
        const datasort = moment(String(date)).format("DD MMMM");
        return datasort;
      } else {
        if (days != null) {
          return days;
        }
      }
    },
    async SmenaActual(value) {
      await axios
        .get(`/api/v1/smeni/${value}/`)
        .then((response) => {
          this.smeni = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async Payment() {
      const data = {
        name: this.name,
        surname: this.surname,
        phone: this.phone,
        email: this.email,
        data_smeni: this.data_smeni,
        programm_info: this.programm_info,
        price: this.price,
      };

      await axios
        .post("/api/v1/payments/v2/create", data)
        .then((response) => {
          const { status, confirmation } = response.data;

          if (status !== "success") {
            bulmaToast.toast({
              message: `Что-то пошло не так`,
              type: "is-danger",
            });
            return;
          }

          this.isPaymentProcessing = true;

          const { confirmation_token } = confirmation;
          const checkout = new window.YooMoneyCheckoutWidget({
            confirmation_token,
            error_callback: (error) => {
              bulmaToast.toast({ message: error, type: "is-danger" });
            },
          });

          checkout.on("success", () => {
            bulmaToast.toast({
              message: "Оплата прошла успешно",
              type: "is-success",
            });
            checkout.destroy();
            this.$router.push({
              name: "PaymentV2Confirm",
              query: { result: "success" },
            });
          });

          checkout.on("fail", () => {
            bulmaToast.toast({
              message: "Оплата не прошла",
              type: "is-warning",
            });
            checkout.destroy();
            this.$router.push({
              name: "PaymentV2Confirm",
              query: { result: "error" },
            });
          });

          checkout.render("yookassa-payment-form");
        })
        .catch((error) => {
          bulmaToast.toast({
            message: `Что-то пошло не так: ${error}`,
            type: "is-danger",
          });
        });
    },
  },
  watch: {
    programmId(value) {
      this.programmId = value;
      this.SmenaActual(this.programmId);
      this.GetProgramm(this.programmId);
    },
    // surname_full(value) {
    //   this.fiofull = value + " " + this.name_full
    //   console.log(value)
    // }
  },
  components: {
    Header,
  },
  created() {
    this.GetSmallInfoProgramms();
  },
  computed: {
    price_full() {
      if (this.price > 0) {
        return true;
      } else {
        return false;
      }
    },
    data_uchast_full() {
      if (this.data_uchast.length > 0) {
        return true;
      } else {
        return false;
      }
    },
    name_full() {
      if (this.name.length > 0) {
        return true;
      } else {
        return false;
      }
    },
    phone_full() {
      if (this.phone.length > 0) {
        return true;
      } else {
        return false;
      }
    },
    email_full() {
      if (this.email.length > 0) {
        return true;
      } else {
        return false;
      }
    },
    surname_full() {
      if (this.surname.length > 0) {
        return true;
      } else {
        return false;
      }
    },
    data_smeni_full() {
      if (this.data_smeni != null) {
        return true;
      } else {
        return false;
      }
    },
    programmId_full() {
      if (this.programmId != null) {
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>

<template>
  <Header />
  <main>
    <section class="aboutPage__hero aboutPage-hero">
      <div class="container">
        <div class="aboutPage-hero__row">
          <h1 class="aboutPage-hero__title hero__title">
            <span>Медицина</span>
          </h1>
        </div>
        <div class="card">
          <div class="card__inner">
            На территории базы есть медпункт, в котором ежедневно дежурит врач. Также, дополнительно, на каждой смене присутствует наш врач, который следит за ребятами 24/7 и проводит ежедневный обязательный обход.<br/><br/>
            Все ребята застрахованы на время лагеря в компании РЕСО.
          </div>

          <img
            src="@/assets/img/parents/meditsina.jpeg"
            class="card__img"
            alt="Медицина ЛидерЛайф"
          />
        </div>

      </div>
    </section>
  </main>
 
</template>

<script>
  import Header from '@/components/Header/Header'

  export default {
    name: 'Meditsina',

    components: {
      Header
    },

  }
</script>

<style>
  .card {
    margin-top: 30px;
    border-radius: 20px;
    padding: 30px;
    font-size: 16px;
    line-height: 150%;
    letter-spacing: -0.035em;
    color: #222226;
    background-color: #fff;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }

  .card__inner {
    margin-right: 12px;
  }

  .card__img {
    max-width: 100%;
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 15px;
  }

  @media (max-width: 767px) {
    .card {
      grid-template-columns: repeat(1, 1fr);
    }

    .card__inner {
      margin-right: 0;
      margin-bottom: 12px;
    }
  }
</style>
<template>
  <ModalLayout class="back-call-modal" @close="closeModal">
    <div v-if="!succesText.length && !errorText.length" class="back-call-modal__inner">
      <h2 class="back-call-modal__title"></h2>
      <div class="back-call-modal__subtitle">
        Есть вопросы - мы вам перезвоним!
      </div>
      <form class="back-call-modal__form" @submit.prevent>
        <div class="payment-main__item back-call-modal__input">
          <input type="text" v-model="name" :class="{ 'notinclude': name == '' && inclinpts }">
          <label for="fio" :class="{ 'active': name.length }">ФИО</label>
        </div>
        <div class="payment-main__item back-call-modal__input">
          <input id="phone" v-model="phone" type="tel" v-mask="'+# (###) ###-##-##'"
            :class="{ 'notinclude': phone == '' && inclinpts }">
          <label for="phone" :class="{ 'active': phone.length }">Телефон*</label>
        </div>
        <button v-if="!isLoading" class="payment-card__btn back-call-modal__btn" type="button" @click="checkFormData">
          Отправить
        </button>
        <button v-if="isLoading" disabled class="payment-card__btn back-call-modal__btn" type="button"
          @click="checkFormData">
          Отправляем данные...
        </button>
      </form>
    </div>
    <div v-else class="back-call-modal__inner">
      <h2 class="back-call-modal__title">
        {{ succesText }}
        {{ errorText }}
      </h2>
    </div>
  </ModalLayout>
</template>

<script>
import ModalLayout from '@/components/wrappers/ModalLayout'
import { mask } from 'vue-the-mask'
import axios from 'axios'

export default {
  name: 'BackCallModal',

  directives: { mask },

  props: {
    isActiveModal: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      name: '',
      phone: '',
      inclinpts: false,
      succesText: '',
      errorText: '',
      isLoading: false
    }
  },

  components: {
    ModalLayout
  },

  methods: {
    closeModal() {
      this.$emit('close')
    },

    async checkFormData() {
      this.inclinpts = false
      if (this.name.length && this.phone.length) {
        await this.sendData()
        return
      }
      this.inclinpts = true
    },

    async sendData() {
      this.isLoading = true;

      const data = {
        name: this.name,
        phone: this.phone,
      }

      await axios
        .post('/api/v1/email/callback', data)
        .then(response => {
          if (response.status === 200) {
            this.succesText = 'Мы получили заявку, скоро с вами свяжемся! 😊'
          } else {
            throw new Error();
          }
        })
        .catch(error => {
          this.errorText = 'К сожалению, что-то пошло не так 😕'
        })
        .finally(() => {
          this.isLoading = false;
        })
    }
  }
}
</script>

<style scoped>
.back-call-modal {
  display: flex;
  align-items: center;
  justify-content: center;
}

.back-call-modal__inner {
  display: flex;
  flex-direction: column;
  background: white;
  padding: 24px 20px;
  width: 100%;
  border-radius: 20px;
  box-sizing: border-box;

  @media only screen and (min-width: 600px) {
    width: 500px;
  }
}

.back-call-modal__title {
  font-size: 24px;
  font-weight: 600;
  color: #222226;
  margin: 16px 0;
  line-height: 1.4;
  text-align: center;
}

.back-call-modal__subtitle {
  font-size: 20px;
  color: #222226;
  margin-bottom: 16px;
  line-height: 1.4;
  text-align: center;
}

.back-call-modal__input {
  margin-bottom: 16px;
}
</style>
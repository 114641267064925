<template>
  <div class="modal-layout">
    <div class="modal-layout__inner">
      <svg @click="close()" class="modal-layout__closing-button" width="36" height="36" viewBox="0 0 36 36" fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path d="M27 9C18 15.5 11.5 20.5 9 27" stroke="#7C57FC" stroke-width="2" stroke-linecap="round"
          stroke-linejoin="round" />
        <path d="M9 9C16.0294 16.0294 21.5 19 27 27" stroke="#7C57FC" stroke-width="2" stroke-linecap="round"
          stroke-linejoin="round" />
      </svg>
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: "ModalLayout",

  methods: {
    close() {
      this.$emit('close')
    }
  }
}
</script>

<style scoped>
.modal-layout {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 100000;
  top: 0;
  right: 0;
  height: 100%;
  background: rgba(28, 28, 28, 0.35);
}

.modal-layout__inner {
  position: relative;
}

.modal-layout__closing-button {
  cursor: pointer;
  transition: transform 0.3s ease-in;
  top: 20px;
  right: 20px;
  position: absolute;
}

.modal-layout__closing-button:hover {
  transform: rotate(90deg)
}
</style>